import { S3Client, PutObjectCommand, ListObjectsCommand } from "../_snowpack/pkg/@aws-sdk/client-s3.js";
import { CognitoIdentityClient } from "../_snowpack/pkg/@aws-sdk/client-cognito-identity.js";
import { fromCognitoIdentityPool } from "../_snowpack/pkg/@aws-sdk/credential-provider-cognito-identity.js";
import { v4 as uuidv4 } from '../_snowpack/pkg/uuid.js';

// Initialize the Amazon Cognito credentials provider
const REGION = 'us-west-2';
const IDENTITY_POOL_ID = 'us-west-2:dfb8b28f-f2c3-4b7d-82eb-9a314d90575e';

// Set up the S3 Client and bucket
const s3 = new S3Client({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: IDENTITY_POOL_ID,
  }),
});
const albumBucketName = "proexp.net-public";

export default class UploadClient {
  // Image Upload
  static async addPhoto(files) {
    try {
      const albumPhotosKey = "uploads/";
      const data = await s3.send(
          new ListObjectsCommand({
            Prefix: albumPhotosKey,
            Bucket: albumBucketName
          })
      );
      const file = files[0];
      let fileExtension = ".jpg";
      switch (file.type) {
        case 'image/png':
          fileExtension = ".png";
          break;
        case 'image/jpeg':
          fileExtension = ".jpg";
          break;
      }
      const photoKey = albumPhotosKey + uuidv4() + fileExtension; // Construct the new file name
      const uploadParams = {
        Bucket: albumBucketName,
        Key: photoKey,
        ContentType: file.type,
        Body: file
      };
      try {
        const data = await s3.send(new PutObjectCommand(uploadParams));
        return 'https://s3.us-west-2.amazonaws.com/' + albumBucketName + '/' + photoKey;
      } catch (err) {
        return alert("There was an error uploading your photo.\n\nPlease note that only .jpg and .png file are accepted.");
      }
    } catch (err) {
      if (!files.length) {
        return alert("Choose a file to upload first.");
      }
    }
  };

  // Video Upload
  static async addVideo(files) {
    try {
      const albumVideosKey = "uploads/";
      const data = await s3.send(
          new ListObjectsCommand({
            Prefix: albumVideosKey,
            Bucket: albumBucketName
          })
      );
      const file = files[0];
      
      // Check file size -- in bytes (20 MB max)
      if (file.size > 20971520) {
        return alert("Your video file is too large.\n\nPlease trim your file (approx. 15 seconds or 20 MB max) and try again.");
      }
   
      // TODO: Check file run time
      // var vid = document.createElement('video');
      // var fileURL = URL.createObjectURL(file);
      // vid.src = fileURL;
      // vid.ondurationchange = function() {
      //   if (this.duration > 15) {
      //     return alert("Your video file must be less than 15 seconds.\n\nPlease trim your file and try again.");
      //   }
      // };

      let fileExtension = ".mp4";
      switch (file.type) {
        case 'video/mp4':
          fileExtension = ".mp4";
          break;
        case 'video/quicktime':
          fileExtension = ".mov";
          break;
      }
      const videoKey = albumVideosKey + uuidv4() + fileExtension; // Construct the new file name
      const uploadParams = {
        Bucket: albumBucketName,
        Key: videoKey,
        ContentType: file.type,
        Body: file
      };
      try {
        const data = await s3.send(new PutObjectCommand(uploadParams));
        return 'https://s3.us-west-2.amazonaws.com/' + albumBucketName + '/' + videoKey;
      } catch (err) {
        console.debug(err);
        return alert("There was an error uploading your video.\n\nPlease note that only .mp4 and .mov are accepted.");
      }
    } catch (err) {
      if (!files.length) {
        return alert("Choose a file to upload first.");
      }
    }
  };
}