import './CartCheckout.svelte.css';
/* src/components/CartCheckout.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import api from "../ApiClient.js";
import OffsetButton from "./OffsetButtons.svelte.js";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("PROCEED TO CHECKOUT");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let section;
	let h30;
	let t0;
	let t1_value = displayMoneyAmount(/*checkout*/ ctx[0].data.node.subtotalPrice) + "";
	let t1;
	let t2;
	let t3_value = /*checkout*/ ctx[0].data.node.currencyCode + "";
	let t3;
	let t4;
	let p;
	let t6;
	let offsetbutton;
	let t7;
	let h31;
	let current;

	offsetbutton = new OffsetButton({
			props: {
				dark: true,
				color: "#494949",
				customStyle: "width: 55%; color: white;",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	offsetbutton.$on("click", /*handleProceedToCheckout*/ ctx[1]);

	return {
		c() {
			section = element("section");
			h30 = element("h3");
			t0 = text("SUBTOTAL: $");
			t1 = text(t1_value);
			t2 = space();
			t3 = text(t3_value);
			t4 = space();
			p = element("p");
			p.textContent = "Taxes and shipping calculated at checkout";
			t6 = space();
			create_component(offsetbutton.$$.fragment);
			t7 = space();
			h31 = element("h3");
			h31.innerHTML = `<a href="/#products-anchor" class="svelte-1punedg">Continue shopping</a>`;
			attr(p, "class", "checkout-info svelte-1punedg");
			attr(h31, "class", "cart-secondary-title centered-text svelte-1punedg");
			attr(section, "class", "cart-checkout svelte-1punedg");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, h30);
			append(h30, t0);
			append(h30, t1);
			append(h30, t2);
			append(h30, t3);
			append(section, t4);
			append(section, p);
			append(section, t6);
			mount_component(offsetbutton, section, null);
			append(section, t7);
			append(section, h31);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*checkout*/ 1) && t1_value !== (t1_value = displayMoneyAmount(/*checkout*/ ctx[0].data.node.subtotalPrice) + "")) set_data(t1, t1_value);
			if ((!current || dirty & /*checkout*/ 1) && t3_value !== (t3_value = /*checkout*/ ctx[0].data.node.currencyCode + "")) set_data(t3, t3_value);
			const offsetbutton_changes = {};

			if (dirty & /*$$scope*/ 8) {
				offsetbutton_changes.$$scope = { dirty, ctx };
			}

			offsetbutton.$set(offsetbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(offsetbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(offsetbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_component(offsetbutton);
		}
	};
}

function displayMoneyAmount(moneyAmount) {
	return parseFloat(moneyAmount).toFixed(2).toString();
}

function instance($$self, $$props, $$invalidate) {
	let { checkout } = $$props;
	let { checkoutId } = $$props;

	async function handleProceedToCheckout() {
		try {
			let res = await api.getWebCheckoutUrl(checkoutId);
			window.location.href = res.data.node.webUrl;
		} catch(err) {
			alert(err);
		}
	}

	$$self.$$set = $$props => {
		if ('checkout' in $$props) $$invalidate(0, checkout = $$props.checkout);
		if ('checkoutId' in $$props) $$invalidate(2, checkoutId = $$props.checkoutId);
	};

	return [checkout, handleProceedToCheckout, checkoutId];
}

class CartCheckout extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { checkout: 0, checkoutId: 2 });
	}
}

export default CartCheckout;