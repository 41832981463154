import './Infomercial.svelte.css';
/* src/components/Infomercial.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	space,
	src_url_equal,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
import MediaQuery from "./MediaQuery.svelte.js";

function create_else_block(ctx) {
	let div0;
	let video;
	let video_src_value;
	let t;
	let div1;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			video = element("video");
			t = space();
			div1 = element("div");
			div1.innerHTML = `<img src="/img/dog-side.png" alt="Infomercial" class="svelte-hxdzbr"/>`;
			video.controls = true;
			if (!src_url_equal(video.src, video_src_value = "/vid/infomercial.mp4#t=0.5")) attr(video, "src", video_src_value);
			attr(video, "class", "svelte-hxdzbr");
			attr(div0, "class", "content svelte-hxdzbr");
			attr(div1, "class", "header svelte-hxdzbr");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, video);
			/*video_binding_1*/ ctx[4](video);
			insert(target, t, anchor);
			insert(target, div1, anchor);

			if (!mounted) {
				dispose = listen(video, "loadedmetadata", /*loadedmetadata_handler_1*/ ctx[3]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div0);
			/*video_binding_1*/ ctx[4](null);
			if (detaching) detach(t);
			if (detaching) detach(div1);
			mounted = false;
			dispose();
		}
	};
}

// (10:6) {#if matches}
function create_if_block(ctx) {
	let div0;
	let t;
	let div1;
	let video;
	let video_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			div0 = element("div");
			div0.innerHTML = `<img src="/img/dog-side.png" alt="Infomercial" class="svelte-hxdzbr"/>`;
			t = space();
			div1 = element("div");
			video = element("video");
			attr(div0, "class", "header svelte-hxdzbr");
			video.controls = true;
			if (!src_url_equal(video.src, video_src_value = "/vid/infomercial.mp4#t=0.5")) attr(video, "src", video_src_value);
			attr(video, "class", "svelte-hxdzbr");
			attr(div1, "class", "content svelte-hxdzbr");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			insert(target, t, anchor);
			insert(target, div1, anchor);
			append(div1, video);
			/*video_binding*/ ctx[2](video);

			if (!mounted) {
				dispose = listen(video, "loadedmetadata", /*loadedmetadata_handler*/ ctx[1]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t);
			if (detaching) detach(div1);
			/*video_binding*/ ctx[2](null);
			mounted = false;
			dispose();
		}
	};
}

// (9:4) <MediaQuery query="only screen and (min-width: 769px)" let:matches>
function create_default_slot(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*matches*/ ctx[5]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let mediaquery;
	let current;

	mediaquery = new MediaQuery({
			props: {
				query: "only screen and (min-width: 769px)",
				$$slots: {
					default: [
						create_default_slot,
						({ matches }) => ({ 5: matches }),
						({ matches }) => matches ? 32 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(mediaquery.$$.fragment);
			attr(div0, "class", "main svelte-hxdzbr");
			attr(div1, "class", "container svelte-hxdzbr");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(mediaquery, div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const mediaquery_changes = {};

			if (dirty & /*$$scope, vid, matches*/ 97) {
				mediaquery_changes.$$scope = { dirty, ctx };
			}

			mediaquery.$set(mediaquery_changes);
		},
		i(local) {
			if (current) return;
			transition_in(mediaquery.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mediaquery.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(mediaquery);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let vid;
	const loadedmetadata_handler = () => $$invalidate(0, vid.currentTime = 0.5, vid);

	function video_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			vid = $$value;
			$$invalidate(0, vid);
		});
	}

	const loadedmetadata_handler_1 = () => $$invalidate(0, vid.currentTime = 0.5, vid);

	function video_binding_1($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			vid = $$value;
			$$invalidate(0, vid);
		});
	}

	return [
		vid,
		loadedmetadata_handler,
		video_binding,
		loadedmetadata_handler_1,
		video_binding_1
	];
}

class Infomercial extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Infomercial;