import { r as resolveEndpoint, N as NoOpLogger, p as parseUrl, c as calculateBodyLength, d as defaultUserAgent, D as DEFAULT_MAX_ATTEMPTS, i as invalidProvider, F as FetchHttpHandler, l as loadConfigsForDefaultMode, a as DEFAULT_RETRY_MODE, b as build, s as streamCollector, e as DEFAULT_USE_DUALSTACK_ENDPOINT, f as DEFAULT_USE_FIPS_ENDPOINT, g as resolveDefaultsModeConfig, C as Client, h as resolveRegionConfig, j as resolveEndpointConfig, k as resolveRetryConfig, m as resolveAwsAuthConfig, n as resolveUserAgentConfig, o as getRetryPlugin, q as getContentLengthPlugin, t as getHostHeaderPlugin, u as getLoggerPlugin, v as getRecursionDetectionPlugin, w as getUserAgentPlugin, x as resolveHostHeaderConfig } from '../common/resolveDefaultsModeConfig-3d61721d.js';
import { f as fromBase64, t as toBase64, a as fromUtf8, b as toUtf8 } from '../common/lazy-json-f4438c0b.js';
import '../common/_commonjsHelpers-eb5a497e.js';
import '../common/v4-c5ee5148.js';

const resolveClientEndpointParameters = (options) => {
    return {
        ...options,
        useDualstackEndpoint: options.useDualstackEndpoint ?? false,
        useFipsEndpoint: options.useFipsEndpoint ?? false,
        defaultSigningName: "cognito-identity",
    };
};

const name = "@aws-sdk/client-cognito-identity";
const description = "AWS SDK for JavaScript Cognito Identity Client for Node.js, Browser and React Native";
const version = "3.378.0";
const scripts = {
  build: "concurrently 'yarn:build:cjs' 'yarn:build:es' 'yarn:build:types'",
  "build:cjs": "tsc -p tsconfig.cjs.json",
  "build:docs": "typedoc",
  "build:es": "tsc -p tsconfig.es.json",
  "build:include:deps": "lerna run --scope $npm_package_name --include-dependencies build",
  "build:types": "tsc -p tsconfig.types.json",
  "build:types:downlevel": "downlevel-dts dist-types dist-types/ts3.4",
  clean: "rimraf ./dist-* && rimraf *.tsbuildinfo",
  "extract:docs": "api-extractor run --local",
  "generate:client": "node ../../scripts/generate-clients/single-service --solo cognito-identity",
  "test:e2e": "ts-mocha test/**/*.ispec.ts && karma start karma.conf.js"
};
const main = "./dist-cjs/index.js";
const types = "./dist-types/index.d.ts";
const module = "./dist-es/index.js";
const sideEffects = false;
const dependencies = {
  "@aws-crypto/sha256-browser": "3.0.0",
  "@aws-crypto/sha256-js": "3.0.0",
  "@aws-sdk/client-sts": "3.378.0",
  "@aws-sdk/credential-provider-node": "3.378.0",
  "@aws-sdk/middleware-host-header": "3.378.0",
  "@aws-sdk/middleware-logger": "3.378.0",
  "@aws-sdk/middleware-recursion-detection": "3.378.0",
  "@aws-sdk/middleware-signing": "3.378.0",
  "@aws-sdk/middleware-user-agent": "3.378.0",
  "@aws-sdk/types": "3.378.0",
  "@aws-sdk/util-endpoints": "3.378.0",
  "@aws-sdk/util-user-agent-browser": "3.378.0",
  "@aws-sdk/util-user-agent-node": "3.378.0",
  "@smithy/config-resolver": "^2.0.1",
  "@smithy/fetch-http-handler": "^2.0.1",
  "@smithy/hash-node": "^2.0.1",
  "@smithy/invalid-dependency": "^2.0.1",
  "@smithy/middleware-content-length": "^2.0.1",
  "@smithy/middleware-endpoint": "^2.0.1",
  "@smithy/middleware-retry": "^2.0.1",
  "@smithy/middleware-serde": "^2.0.1",
  "@smithy/middleware-stack": "^2.0.0",
  "@smithy/node-config-provider": "^2.0.1",
  "@smithy/node-http-handler": "^2.0.1",
  "@smithy/protocol-http": "^2.0.1",
  "@smithy/smithy-client": "^2.0.1",
  "@smithy/types": "^2.0.2",
  "@smithy/url-parser": "^2.0.1",
  "@smithy/util-base64": "^2.0.0",
  "@smithy/util-body-length-browser": "^2.0.0",
  "@smithy/util-body-length-node": "^2.0.0",
  "@smithy/util-defaults-mode-browser": "^2.0.1",
  "@smithy/util-defaults-mode-node": "^2.0.1",
  "@smithy/util-retry": "^2.0.0",
  "@smithy/util-utf8": "^2.0.0",
  tslib: "^2.5.0"
};
const devDependencies = {
  "@aws-sdk/client-iam": "3.378.0",
  "@smithy/service-client-documentation-generator": "^2.0.0",
  "@tsconfig/node14": "1.0.3",
  "@types/chai": "^4.2.11",
  "@types/mocha": "^8.0.4",
  "@types/node": "^14.14.31",
  concurrently: "7.0.0",
  "downlevel-dts": "0.10.1",
  rimraf: "3.0.2",
  typedoc: "0.23.23",
  typescript: "~4.9.5"
};
const engines = {
  node: ">=14.0.0"
};
const typesVersions = {
  "<4.0": {
    "dist-types/*": [
      "dist-types/ts3.4/*"
    ]
  }
};
const files = [
  "dist-*/**"
];
const author = {
  name: "AWS SDK for JavaScript Team",
  url: "https://aws.amazon.com/javascript/"
};
const license = "Apache-2.0";
const browser = {
  "./dist-es/runtimeConfig": "./dist-es/runtimeConfig.browser"
};
const homepage = "https://github.com/aws/aws-sdk-js-v3/tree/main/clients/client-cognito-identity";
const repository = {
  type: "git",
  url: "https://github.com/aws/aws-sdk-js-v3.git",
  directory: "clients/client-cognito-identity"
};
var packageInfo = {
  name: name,
  description: description,
  version: version,
  scripts: scripts,
  main: main,
  types: types,
  module: module,
  sideEffects: sideEffects,
  dependencies: dependencies,
  devDependencies: devDependencies,
  engines: engines,
  typesVersions: typesVersions,
  files: files,
  author: author,
  license: license,
  browser: browser,
  "react-native": {
  "./dist-es/runtimeConfig": "./dist-es/runtimeConfig.native"
},
  homepage: homepage,
  repository: repository
};

const p = "required", q = "fn", r = "argv", s = "ref";
const a = "PartitionResult", b = "tree", c = "error", d = "endpoint", e = { [p]: false, "type": "String" }, f = { [p]: true, "default": false, "type": "Boolean" }, g = { [s]: "Endpoint" }, h = { [q]: "booleanEquals", [r]: [{ [s]: "UseFIPS" }, true] }, i = { [q]: "booleanEquals", [r]: [{ [s]: "UseDualStack" }, true] }, j = {}, k = { [q]: "booleanEquals", [r]: [true, { [q]: "getAttr", [r]: [{ [s]: a }, "supportsFIPS"] }] }, l = { [q]: "booleanEquals", [r]: [true, { [q]: "getAttr", [r]: [{ [s]: a }, "supportsDualStack"] }] }, m = [g], n = [h], o = [i];
const _data = { version: "1.0", parameters: { Region: e, UseDualStack: f, UseFIPS: f, Endpoint: e }, rules: [{ conditions: [{ [q]: "aws.partition", [r]: [{ [s]: "Region" }], assign: a }], type: b, rules: [{ conditions: [{ [q]: "isSet", [r]: m }, { [q]: "parseURL", [r]: m, assign: "url" }], type: b, rules: [{ conditions: n, error: "Invalid Configuration: FIPS and custom endpoint are not supported", type: c }, { type: b, rules: [{ conditions: o, error: "Invalid Configuration: Dualstack and custom endpoint are not supported", type: c }, { endpoint: { url: g, properties: j, headers: j }, type: d }] }] }, { conditions: [h, i], type: b, rules: [{ conditions: [k, l], type: b, rules: [{ endpoint: { url: "https://cognito-identity-fips.{Region}.{PartitionResult#dualStackDnsSuffix}", properties: j, headers: j }, type: d }] }, { error: "FIPS and DualStack are enabled, but this partition does not support one or both", type: c }] }, { conditions: n, type: b, rules: [{ conditions: [k], type: b, rules: [{ type: b, rules: [{ endpoint: { url: "https://cognito-identity-fips.{Region}.{PartitionResult#dnsSuffix}", properties: j, headers: j }, type: d }] }] }, { error: "FIPS is enabled but this partition does not support FIPS", type: c }] }, { conditions: o, type: b, rules: [{ conditions: [l], type: b, rules: [{ endpoint: { url: "https://cognito-identity.{Region}.{PartitionResult#dualStackDnsSuffix}", properties: j, headers: j }, type: d }] }, { error: "DualStack is enabled but this partition does not support DualStack", type: c }] }, { endpoint: { url: "https://cognito-identity.{Region}.{PartitionResult#dnsSuffix}", properties: j, headers: j }, type: d }] }] };
const ruleSet = _data;

const defaultEndpointResolver = (endpointParams, context = {}) => {
    return resolveEndpoint(ruleSet, {
        endpointParams: endpointParams,
        logger: context.logger,
    });
};

const getRuntimeConfig = (config) => ({
    apiVersion: "2014-06-30",
    base64Decoder: config?.base64Decoder ?? fromBase64,
    base64Encoder: config?.base64Encoder ?? toBase64,
    disableHostPrefix: config?.disableHostPrefix ?? false,
    endpointProvider: config?.endpointProvider ?? defaultEndpointResolver,
    logger: config?.logger ?? new NoOpLogger(),
    serviceId: config?.serviceId ?? "Cognito Identity",
    urlParser: config?.urlParser ?? parseUrl,
    utf8Decoder: config?.utf8Decoder ?? fromUtf8,
    utf8Encoder: config?.utf8Encoder ?? toUtf8,
});

const getRuntimeConfig$1 = (config) => {
    const defaultsMode = resolveDefaultsModeConfig(config);
    const defaultConfigProvider = () => defaultsMode().then(loadConfigsForDefaultMode);
    const clientSharedValues = getRuntimeConfig(config);
    return {
        ...clientSharedValues,
        ...config,
        runtime: "browser",
        defaultsMode,
        bodyLengthChecker: config?.bodyLengthChecker ?? calculateBodyLength,
        credentialDefaultProvider: config?.credentialDefaultProvider ?? ((_) => () => Promise.reject(new Error("Credential is missing"))),
        defaultUserAgentProvider: config?.defaultUserAgentProvider ??
            defaultUserAgent({ serviceId: clientSharedValues.serviceId, clientVersion: packageInfo.version }),
        maxAttempts: config?.maxAttempts ?? DEFAULT_MAX_ATTEMPTS,
        region: config?.region ?? invalidProvider("Region is missing"),
        requestHandler: config?.requestHandler ?? new FetchHttpHandler(defaultConfigProvider),
        retryMode: config?.retryMode ?? (async () => (await defaultConfigProvider()).retryMode || DEFAULT_RETRY_MODE),
        sha256: config?.sha256 ?? build.Sha256,
        streamCollector: config?.streamCollector ?? streamCollector,
        useDualstackEndpoint: config?.useDualstackEndpoint ?? (() => Promise.resolve(DEFAULT_USE_DUALSTACK_ENDPOINT)),
        useFipsEndpoint: config?.useFipsEndpoint ?? (() => Promise.resolve(DEFAULT_USE_FIPS_ENDPOINT)),
    };
};

class CognitoIdentityClient extends Client {
    constructor(configuration) {
        const _config_0 = getRuntimeConfig$1(configuration);
        const _config_1 = resolveClientEndpointParameters(_config_0);
        const _config_2 = resolveRegionConfig(_config_1);
        const _config_3 = resolveEndpointConfig(_config_2);
        const _config_4 = resolveRetryConfig(_config_3);
        const _config_5 = resolveHostHeaderConfig(_config_4);
        const _config_6 = resolveAwsAuthConfig(_config_5);
        const _config_7 = resolveUserAgentConfig(_config_6);
        super(_config_7);
        this.config = _config_7;
        this.middlewareStack.use(getRetryPlugin(this.config));
        this.middlewareStack.use(getContentLengthPlugin(this.config));
        this.middlewareStack.use(getHostHeaderPlugin(this.config));
        this.middlewareStack.use(getLoggerPlugin(this.config));
        this.middlewareStack.use(getRecursionDetectionPlugin(this.config));
        this.middlewareStack.use(getUserAgentPlugin(this.config));
    }
    destroy() {
        super.destroy();
    }
}

export { CognitoIdentityClient };
