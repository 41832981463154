import './VideoPreview.svelte.css';
/* src/components/VideoPreview.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	src_url_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div;
	let video;
	let video_src_value;

	return {
		c() {
			div = element("div");
			video = element("video");
			if (!src_url_equal(video.src, video_src_value = /*videoUrl*/ ctx[0])) attr(video, "src", video_src_value);
			video.autoplay = true;
			video.playsInline = true;
			attr(video, "class", "svelte-15f7yeq");
			attr(div, "class", "container svelte-15f7yeq");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, video);
		},
		p(ctx, [dirty]) {
			if (dirty & /*videoUrl*/ 1 && !src_url_equal(video.src, video_src_value = /*videoUrl*/ ctx[0])) {
				attr(video, "src", video_src_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { videoUrl } = $$props;

	$$self.$$set = $$props => {
		if ('videoUrl' in $$props) $$invalidate(0, videoUrl = $$props.videoUrl);
	};

	return [videoUrl];
}

class VideoPreview extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { videoUrl: 0 });
	}
}

export default VideoPreview;