import './Products.svelte.css';
/* src/components/Products.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import ProductCTA from "./ProductCTA.svelte.js";

function create_fragment(ctx) {
	let a;
	let t;
	let div2;
	let div1;
	let div0;
	let productcta;
	let current;

	productcta = new ProductCTA({
			props: {
				borderColor: "#ff89de",
				text: "BUY PHOTOS",
				type: "photo",
				imgSrc: "/img/photo_product.png"
			}
		});

	return {
		c() {
			a = element("a");
			t = space();
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");
			create_component(productcta.$$.fragment);
			attr(a, "id", "products-anchor");
			attr(a, "class", "svelte-1n5m1e1");
			attr(div0, "class", "product svelte-1n5m1e1");
			attr(div1, "class", "collection svelte-1n5m1e1");
			attr(div2, "class", "container svelte-1n5m1e1");
		},
		m(target, anchor) {
			insert(target, a, anchor);
			insert(target, t, anchor);
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, div0);
			mount_component(productcta, div0, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(productcta.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(productcta.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(a);
			if (detaching) detach(t);
			if (detaching) detach(div2);
			destroy_component(productcta);
		}
	};
}

class Products extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Products;