import './NavBar.svelte.css';
/* src/components/NavBar.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { Link } from "../../_snowpack/pkg/svelte-routing.js";
import api from "../ApiClient.js";
import { onMount, onDestroy } from "../../_snowpack/pkg/svelte.js";
import { getSavedCheckoutId, setSavedCheckoutId, isMobile } from "../util.js";
import Modal from '../../_snowpack/pkg/svelte-simple-modal.js';
import { bind } from '../../_snowpack/pkg/svelte-simple-modal.js';
import PlayCredentials from "./PlayCredentials.svelte.js";
import { playModal } from "../stores.js";
import MediaQuery from "./MediaQuery.svelte.js";
import { cartItemCount } from '../stores.js';

function create_else_block(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				style: "height: 100%; position: absolute; left: 0;",
				to: "/",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

// (77:4) {#if matches}
function create_if_block_2(ctx) {
	let link;
	let current;

	link = new Link({
			props: {
				style: "height: 100%; position: absolute; left: 0;",
				to: "/",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(link.$$.fragment);
		},
		m(target, anchor) {
			mount_component(link, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(link, detaching);
		}
	};
}

// (80:6) <Link style="height: 100%; position: absolute; left: 0;" to="/">
function create_default_slot_2(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "class", "logo svelte-sscidw");
			if (!src_url_equal(img.src, img_src_value = "/img/logo.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (78:6) <Link style="height: 100%; position: absolute; left: 0;" to="/">
function create_default_slot_1(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "class", "logo svelte-sscidw");
			if (!src_url_equal(img.src, img_src_value = "/img/top-logo.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (76:2) <MediaQuery query="only screen and (min-width: 769px)" let:matches>
function create_default_slot(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block_2, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*matches*/ ctx[11]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (83:2) {#if isMobile()}
function create_if_block_1(ctx) {
	let div;
	let button;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button = element("button");
			button.textContent = "Watch MyPetAR";
			attr(button, "class", "watch-button svelte-sscidw");
			attr(div, "class", "watch-container svelte-sscidw");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button);

			if (!mounted) {
				dispose = listen(button, "click", /*handlePlayModalOpen*/ ctx[6]);
				mounted = true;
			}
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

// (93:18) {#if itemCount > 0}
function create_if_block(ctx) {
	let t0;
	let t1_value = (/*itemCount*/ ctx[0] = /*itemCount*/ ctx[0]) + "";
	let t1;
	let t2;

	return {
		c() {
			t0 = text("(");
			t1 = text(t1_value);
			t2 = text(")");
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
			insert(target, t2, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*itemCount*/ 1 && t1_value !== (t1_value = (/*itemCount*/ ctx[0] = /*itemCount*/ ctx[0]) + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
			if (detaching) detach(t2);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let mediaquery;
	let t0;
	let show_if = isMobile();
	let t1;
	let img;
	let img_src_value;
	let t2;
	let div1;
	let div0;
	let a0;
	let t4;
	let a1;
	let t6;
	let a2;
	let t8;
	let a3;
	let span3;
	let t9;
	let t10;
	let modal;
	let current;
	let mounted;
	let dispose;

	mediaquery = new MediaQuery({
			props: {
				query: "only screen and (min-width: 769px)",
				$$slots: {
					default: [
						create_default_slot,
						({ matches }) => ({ 11: matches }),
						({ matches }) => matches ? 2048 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	let if_block0 = show_if && create_if_block_1(ctx);
	let if_block1 = /*itemCount*/ ctx[0] > 0 && create_if_block(ctx);

	modal = new Modal({
			props: {
				show: /*$playModal*/ ctx[3],
				closeButton: false,
				styleWindowWrap: { marginTop: "-38%" },
				styleWindow: { background: "#5cbdf9" }
			}
		});

	modal.$on("closed", /*handlePlayModalClosed*/ ctx[7]);

	return {
		c() {
			div2 = element("div");
			create_component(mediaquery.$$.fragment);
			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			img = element("img");
			t2 = space();
			div1 = element("div");
			div0 = element("div");
			a0 = element("a");
			a0.innerHTML = `<span class="svelte-sscidw">HOME</span>`;
			t4 = space();
			a1 = element("a");
			a1.innerHTML = `<span class="svelte-sscidw">ABOUT US</span>`;
			t6 = space();
			a2 = element("a");
			a2.innerHTML = `<span class="svelte-sscidw">FAQ</span>`;
			t8 = space();
			a3 = element("a");
			span3 = element("span");
			t9 = text("CART");
			if (if_block1) if_block1.c();
			t10 = space();
			create_component(modal.$$.fragment);
			if (!src_url_equal(img.src, img_src_value = "/img/menu-icon.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "mypetar.com");
			attr(img, "class", "menu-icon svelte-sscidw");
			attr(a0, "href", "/");
			attr(a0, "class", "svelte-sscidw");
			attr(a1, "href", "/#about");
			attr(a1, "class", "svelte-sscidw");
			attr(a2, "href", "/#faq");
			attr(a2, "class", "svelte-sscidw");
			attr(span3, "class", "svelte-sscidw");
			attr(a3, "href", "/cart");
			attr(a3, "class", "svelte-sscidw");
			attr(div0, "class", "nav svelte-sscidw");
			attr(div1, "class", "drawer svelte-sscidw");
			toggle_class(div1, "menuvisible", /*menuvisible*/ ctx[2]);
			attr(div2, "class", "navbar svelte-sscidw");
			toggle_class(div2, "shadow", /*shadow*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			mount_component(mediaquery, div2, null);
			append(div2, t0);
			if (if_block0) if_block0.m(div2, null);
			append(div2, t1);
			append(div2, img);
			append(div2, t2);
			append(div2, div1);
			append(div1, div0);
			append(div0, a0);
			append(div0, t4);
			append(div0, a1);
			append(div0, t6);
			append(div0, a2);
			append(div0, t8);
			append(div0, a3);
			append(a3, span3);
			append(span3, t9);
			if (if_block1) if_block1.m(span3, null);
			insert(target, t10, anchor);
			mount_component(modal, target, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen(img, "click", /*handleMenuClick*/ ctx[4]),
					listen(div0, "click", /*collapseIfMobile*/ ctx[5])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const mediaquery_changes = {};

			if (dirty & /*$$scope, matches*/ 6144) {
				mediaquery_changes.$$scope = { dirty, ctx };
			}

			mediaquery.$set(mediaquery_changes);
			if (show_if) if_block0.p(ctx, dirty);

			if (/*itemCount*/ ctx[0] > 0) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(span3, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (!current || dirty & /*menuvisible*/ 4) {
				toggle_class(div1, "menuvisible", /*menuvisible*/ ctx[2]);
			}

			if (!current || dirty & /*shadow*/ 2) {
				toggle_class(div2, "shadow", /*shadow*/ ctx[1]);
			}

			const modal_changes = {};
			if (dirty & /*$playModal*/ 8) modal_changes.show = /*$playModal*/ ctx[3];
			modal.$set(modal_changes);
		},
		i(local) {
			if (current) return;
			transition_in(mediaquery.$$.fragment, local);
			transition_in(modal.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mediaquery.$$.fragment, local);
			transition_out(modal.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_component(mediaquery);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (detaching) detach(t10);
			destroy_component(modal, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $playModal;
	component_subscribe($$self, playModal, $$value => $$invalidate(3, $playModal = $$value));
	let itemCount = 0;
	let checkoutId;

	onMount(async () => {
		playModal.set();

		// See if the checkout id is still valid. Invalidate if need be.
		checkoutId = getSavedCheckoutId();

		if (checkoutId) {
			try {
				let checkout = await api.getCheckout(checkoutId);

				if (checkout) {
					$$invalidate(0, itemCount = checkout.data.node.lineItems.edges.length);

					if (checkout.data.node.order) {
						// Order already created from checkout. Create new checkout.
						await createCheckout();

						setSavedCheckoutId(checkoutId);
						$$invalidate(0, itemCount = 0);
					}
				}
			} catch(err) {
				console.log(err);
			}
		}
	});

	let shadow = false;
	let menuvisible = false;

	window.onscroll = () => {
		if (window.scrollY > 10) $$invalidate(1, shadow = true); else $$invalidate(1, shadow = false);
	};

	const handleMenuClick = () => {
		$$invalidate(2, menuvisible = !menuvisible);
	};

	const collapseIfMobile = () => menuvisible && handleMenuClick();

	const handlePlayModalOpen = () => {
		playModal.set(bind(PlayCredentials, {}));
	};

	const handlePlayModalClosed = () => {
		playModal.set();
	};

	const unsubscribe = cartItemCount.subscribe(value => {
		$$invalidate(0, itemCount = value);
	});

	async function createCheckout() {
		let checkoutDeets = await api.createCheckout();

		// Set the checkout id, so we can eventually add items to it
		checkoutId = checkoutDeets.data.checkoutCreate.checkout.id;
	}

	onDestroy(unsubscribe);

	return [
		itemCount,
		shadow,
		menuvisible,
		$playModal,
		handleMenuClick,
		collapseIfMobile,
		handlePlayModalOpen,
		handlePlayModalClosed
	];
}

class NavBar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default NavBar;