import './Product.svelte.css';
/* src/screens/Product.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	init_binding_group,
	insert,
	is_function,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_data,
	set_style,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
import { getSavedCheckoutId, setSavedCheckoutId } from "../util.js";
import api from "../ApiClient.js";
import upload from "../UploadClient.js";
import NavBar from "../components/NavBar.svelte.js";
import Footer from "../components/Footer.svelte.js";
import LoadingAnimation from "../components/LoadingAnimation.svelte.js";
import Modal from '../../_snowpack/pkg/svelte-simple-modal.js';
import { bind } from '../../_snowpack/pkg/svelte-simple-modal.js';
import ThemePreview from '../components/ThemePreview.svelte.js';
import ImagePreview from '../components/ImagePreview.svelte.js';
import VideoPreview from '../components/VideoPreview.svelte.js';
import OffsetButton from "../components/OffsetButtons.svelte.js";
import { navigate } from "../../_snowpack/pkg/svelte-routing.js";
import { modal } from "../stores.js";
import MediaQuery from "../components/MediaQuery.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[57] = list[i];
	return child_ctx;
}

// (161:0) {#if product}
function create_if_block(ctx) {
	let div20;
	let mediaquery;
	let t0;
	let div19;
	let div1;
	let t1;
	let div0;
	let video;
	let video_src_value;
	let t2;
	let div18;
	let h1;
	let t3_value = /*product*/ ctx[2].title + "";
	let t3;
	let t4;
	let h20;
	let t5;
	let p;
	let raw_value = /*product*/ ctx[2].descriptionHtml + "";
	let t6;
	let div2;
	let span0;
	let t8;
	let select0;
	let t9;
	let div3;
	let span1;
	let t11;
	let input0;
	let t12;
	let div4;
	let span2;
	let t14;
	let label0;
	let input1;
	let strong;
	let t16;
	let label1;
	let input2;
	let t17;
	let t18;
	let div6;
	let span3;
	let t20;
	let div5;
	let select1;
	let option0;
	let option1;
	let option2;
	let option3;
	let option4;
	let option5;
	let t27;
	let offsetbutton0;
	let t28;
	let div11;
	let span4;
	let t30;
	let div10;
	let div7;
	let label2;
	let t32;
	let input3;
	let t33;
	let div8;
	let h21;
	let t35;
	let offsetbutton1;
	let t36;
	let t37;
	let div9;
	let t38;
	let div16;
	let span5;
	let t40;
	let div15;
	let div12;
	let label3;
	let t42;
	let input4;
	let t43;
	let div13;
	let h22;
	let t45;
	let offsetbutton2;
	let t46;
	let t47;
	let div14;
	let t48;
	let div17;
	let offsetbutton3;
	let current;
	let binding_group;
	let mounted;
	let dispose;

	mediaquery = new MediaQuery({
			props: {
				query: "only screen and (min-width: 1900px)",
				$$slots: {
					default: [
						create_default_slot_6,
						({ matches }) => ({ 60: matches }),
						({ matches }) => [0, matches ? 536870912 : 0]
					]
				},
				$$scope: { ctx }
			}
		});

	function select_block_type(ctx, dirty) {
		if (/*product*/ ctx[2].images.edges) return create_if_block_7;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, [-1, -1]);
	let if_block0 = current_block_type(ctx);

	function select_block_type_1(ctx, dirty) {
		if (/*variantComparePrice*/ ctx[7]) return create_if_block_6;
		return create_else_block;
	}

	let current_block_type_1 = select_block_type_1(ctx, [-1, -1]);
	let if_block1 = current_block_type_1(ctx);
	let each_value = /*product*/ ctx[2].variants.edges;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	offsetbutton0 = new OffsetButton({
			props: {
				color: "#ff89de",
				customStyle: "margin-top:16px;",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	offsetbutton0.$on("click", /*handleModalOpen*/ ctx[33]);

	let offsetbutton1_props = {
		color: "#5cbdf9",
		customStyle: "margin-top:16px; margin-right: 2rem;",
		disabled: /*photoButtonDisabled*/ ctx[12],
		$$slots: { default: [create_default_slot_4] },
		$$scope: { ctx }
	};

	offsetbutton1 = new OffsetButton({ props: offsetbutton1_props });
	/*offsetbutton1_binding*/ ctx[49](offsetbutton1);
	offsetbutton1.$on("click", /*addPhoto*/ ctx[27]);
	let if_block2 = /*photoUrl*/ ctx[14] && create_if_block_4(ctx);
	let if_block3 = /*showPhotoUploading*/ ctx[13] && create_if_block_3(ctx);

	let offsetbutton2_props = {
		color: "#5cbdf9",
		customStyle: "margin-top:16px; margin-right: 2rem;",
		disabled: /*videoButtonDisabled*/ ctx[18],
		$$slots: { default: [create_default_slot_2] },
		$$scope: { ctx }
	};

	offsetbutton2 = new OffsetButton({ props: offsetbutton2_props });
	/*offsetbutton2_binding*/ ctx[51](offsetbutton2);
	offsetbutton2.$on("click", /*addVideo*/ ctx[29]);
	let if_block4 = /*videoUrl*/ ctx[20] && create_if_block_2(ctx);
	let if_block5 = /*showVideoUploading*/ ctx[19] && create_if_block_1(ctx);

	let offsetbutton3_props = {
		color: "#f2a240",
		width: "55%",
		customStyle: "min-width: 230px; margin-bottom: 25px;",
		disabled: /*addToCartDisabled*/ ctx[22],
		$$slots: { default: [create_default_slot] },
		$$scope: { ctx }
	};

	offsetbutton3 = new OffsetButton({ props: offsetbutton3_props });
	/*offsetbutton3_binding*/ ctx[52](offsetbutton3);
	offsetbutton3.$on("click", /*addToCheckout*/ ctx[25]);
	binding_group = init_binding_group(/*$$binding_groups*/ ctx[45][0]);

	return {
		c() {
			div20 = element("div");
			create_component(mediaquery.$$.fragment);
			t0 = space();
			div19 = element("div");
			div1 = element("div");
			if_block0.c();
			t1 = space();
			div0 = element("div");
			video = element("video");
			t2 = space();
			div18 = element("div");
			h1 = element("h1");
			t3 = text(t3_value);
			t4 = space();
			h20 = element("h2");
			if_block1.c();
			t5 = space();
			p = element("p");
			t6 = space();
			div2 = element("div");
			span0 = element("span");
			span0.textContent = "SIZE";
			t8 = space();
			select0 = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t9 = space();
			div3 = element("div");
			span1 = element("span");
			span1.textContent = "QTY";
			t11 = space();
			input0 = element("input");
			t12 = space();
			div4 = element("div");
			span2 = element("span");
			span2.textContent = "Would you like to have your photo Cartoonized?";
			t14 = space();
			label0 = element("label");
			input1 = element("input");
			strong = element("strong");
			strong.textContent = "YES, cartoonize my photo!";
			t16 = space();
			label1 = element("label");
			input2 = element("input");
			t17 = text("No");
			t18 = space();
			div6 = element("div");
			span3 = element("span");
			span3.textContent = "Step 1: Select your video theme";
			t20 = space();
			div5 = element("div");
			select1 = element("select");
			option0 = element("option");
			option0.textContent = "Beach";
			option1 = element("option");
			option1.textContent = "Love";
			option2 = element("option");
			option2.textContent = "Field";
			option3 = element("option");
			option3.textContent = "Birthday";
			option4 = element("option");
			option4.textContent = "BFF";
			option5 = element("option");
			option5.textContent = "Sleep";
			t27 = space();
			create_component(offsetbutton0.$$.fragment);
			t28 = space();
			div11 = element("div");
			span4 = element("span");
			span4.textContent = "Step 2: Choose your pet's photo";
			t30 = space();
			div10 = element("div");
			div7 = element("div");
			label2 = element("label");
			label2.textContent = "1.";
			t32 = space();
			input3 = element("input");
			t33 = space();
			div8 = element("div");
			h21 = element("h2");
			h21.textContent = "2.";
			t35 = space();
			create_component(offsetbutton1.$$.fragment);
			t36 = space();
			if (if_block2) if_block2.c();
			t37 = space();
			div9 = element("div");
			if (if_block3) if_block3.c();
			t38 = space();
			div16 = element("div");
			span5 = element("span");
			span5.textContent = "Step 3: Choose video clip (approx. 15 secs or 20 MB max)";
			t40 = space();
			div15 = element("div");
			div12 = element("div");
			label3 = element("label");
			label3.textContent = "1.";
			t42 = space();
			input4 = element("input");
			t43 = space();
			div13 = element("div");
			h22 = element("h2");
			h22.textContent = "2.";
			t45 = space();
			create_component(offsetbutton2.$$.fragment);
			t46 = space();
			if (if_block4) if_block4.c();
			t47 = space();
			div14 = element("div");
			if (if_block5) if_block5.c();
			t48 = space();
			div17 = element("div");
			create_component(offsetbutton3.$$.fragment);
			video.controls = true;
			if (!src_url_equal(video.src, video_src_value = "/vid/infomercial.mp4#t=0.5")) attr(video, "src", video_src_value);
			attr(video, "class", "vid svelte-13yqyyf");
			attr(div0, "class", "main-vid svelte-13yqyyf");
			attr(div1, "class", "flex-child svelte-13yqyyf");
			attr(p, "class", "description svelte-13yqyyf");
			attr(span0, "class", "label svelte-13yqyyf");
			attr(select0, "id", "variant");
			attr(select0, "class", "svelte-13yqyyf");
			if (/*variantSelected*/ ctx[5] === void 0) add_render_callback(() => /*select0_change_handler*/ ctx[42].call(select0));
			attr(div2, "class", "variant svelte-13yqyyf");
			attr(span1, "class", "label svelte-13yqyyf");
			attr(input0, "type", "number");
			attr(input0, "id", "qty");
			input0.value = "1";
			attr(input0, "min", "1");
			attr(input0, "max", "50");
			attr(input0, "class", "svelte-13yqyyf");
			attr(div3, "class", "variant svelte-13yqyyf");
			attr(span2, "class", "label svelte-13yqyyf");
			attr(input1, "type", "radio");
			attr(input1, "name", "ctz");
			input1.__value = "yes";
			input1.value = input1.__value;
			attr(input1, "class", "svelte-13yqyyf");
			set_style(label0, "margin-right", "50px");
			attr(input2, "type", "radio");
			attr(input2, "name", "ctz");
			input2.__value = "no";
			input2.value = input2.__value;
			attr(input2, "class", "svelte-13yqyyf");
			set_style(label1, "font-size", "1.2rem");
			attr(div4, "class", "variant svelte-13yqyyf");
			attr(span3, "class", "prompt svelte-13yqyyf");
			option0.__value = "beach";
			option0.value = option0.__value;
			option1.__value = "love";
			option1.value = option1.__value;
			option2.__value = "field";
			option2.value = option2.__value;
			option3.__value = "birthday";
			option3.value = option3.__value;
			option4.__value = "bff";
			option4.value = option4.__value;
			option5.__value = "sleep";
			option5.value = option5.__value;
			attr(select1, "id", "theme");
			attr(select1, "class", "svelte-13yqyyf");
			attr(div5, "class", "step-container-content svelte-13yqyyf");
			attr(div6, "class", "variant svelte-13yqyyf");
			attr(span4, "class", "prompt svelte-13yqyyf");
			attr(label2, "class", "upload-label svelte-13yqyyf");
			attr(label2, "for", "photoFile");
			attr(input3, "id", "photoFile");
			attr(input3, "class", "action-xs svelte-13yqyyf");
			attr(input3, "type", "file");
			attr(input3, "accept", "image/*");
			attr(div7, "class", "file-input-container svelte-13yqyyf");
			attr(h21, "class", "upload-label svelte-13yqyyf");
			attr(div8, "class", "buttons svelte-13yqyyf");
			attr(div9, "class", "upload-status svelte-13yqyyf");
			attr(div10, "class", "step-container-content upload-container svelte-13yqyyf");
			attr(div11, "class", "variant svelte-13yqyyf");
			attr(span5, "class", "prompt svelte-13yqyyf");
			attr(label3, "class", "upload-label svelte-13yqyyf");
			attr(label3, "for", "videoFile");
			attr(input4, "id", "videoFile");
			attr(input4, "class", "action-xs svelte-13yqyyf");
			attr(input4, "type", "file");
			attr(input4, "accept", "video/*");
			input4.disabled = /*videoFileDisabled*/ ctx[16];
			attr(div12, "class", "file-input-container svelte-13yqyyf");
			attr(h22, "class", "upload-label svelte-13yqyyf");
			attr(div13, "class", "buttons svelte-13yqyyf");
			attr(div14, "class", "upload-status svelte-13yqyyf");
			attr(div15, "class", "step-container-content upload-container svelte-13yqyyf");
			attr(div16, "class", "variant svelte-13yqyyf");
			attr(div17, "class", "actions-container svelte-13yqyyf");
			attr(div18, "class", "flex-child svelte-13yqyyf");
			attr(div19, "class", "flex-container svelte-13yqyyf");
			attr(div20, "class", "main svelte-13yqyyf");
			binding_group.p(input1, input2);
		},
		m(target, anchor) {
			insert(target, div20, anchor);
			mount_component(mediaquery, div20, null);
			append(div20, t0);
			append(div20, div19);
			append(div19, div1);
			if_block0.m(div1, null);
			append(div1, t1);
			append(div1, div0);
			append(div0, video);
			/*video_binding*/ ctx[40](video);
			append(div19, t2);
			append(div19, div18);
			append(div18, h1);
			append(h1, t3);
			append(div18, t4);
			append(div18, h20);
			if_block1.m(h20, null);
			append(div18, t5);
			append(div18, p);
			p.innerHTML = raw_value;
			append(div18, t6);
			append(div18, div2);
			append(div2, span0);
			append(div2, t8);
			append(div2, select0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(select0, null);
				}
			}

			/*select0_binding*/ ctx[41](select0);
			select_option(select0, /*variantSelected*/ ctx[5], true);
			append(div18, t9);
			append(div18, div3);
			append(div3, span1);
			append(div3, t11);
			append(div3, input0);
			/*input0_binding*/ ctx[43](input0);
			append(div18, t12);
			append(div18, div4);
			append(div4, span2);
			append(div4, t14);
			append(div4, label0);
			append(label0, input1);
			input1.checked = input1.__value === /*cartoonize*/ ctx[9];
			append(label0, strong);
			append(div4, t16);
			append(div4, label1);
			append(label1, input2);
			input2.checked = input2.__value === /*cartoonize*/ ctx[9];
			append(label1, t17);
			append(div18, t18);
			append(div18, div6);
			append(div6, span3);
			append(div6, t20);
			append(div6, div5);
			append(div5, select1);
			append(select1, option0);
			append(select1, option1);
			append(select1, option2);
			append(select1, option3);
			append(select1, option4);
			append(select1, option5);
			/*select1_binding*/ ctx[47](select1);
			append(div5, t27);
			mount_component(offsetbutton0, div5, null);
			append(div18, t28);
			append(div18, div11);
			append(div11, span4);
			append(div11, t30);
			append(div11, div10);
			append(div10, div7);
			append(div7, label2);
			append(div7, t32);
			append(div7, input3);
			/*input3_binding*/ ctx[48](input3);
			append(div10, t33);
			append(div10, div8);
			append(div8, h21);
			append(div8, t35);
			mount_component(offsetbutton1, div8, null);
			append(div8, t36);
			if (if_block2) if_block2.m(div8, null);
			append(div10, t37);
			append(div10, div9);
			if (if_block3) if_block3.m(div9, null);
			append(div18, t38);
			append(div18, div16);
			append(div16, span5);
			append(div16, t40);
			append(div16, div15);
			append(div15, div12);
			append(div12, label3);
			append(div12, t42);
			append(div12, input4);
			/*input4_binding*/ ctx[50](input4);
			append(div15, t43);
			append(div15, div13);
			append(div13, h22);
			append(div13, t45);
			mount_component(offsetbutton2, div13, null);
			append(div13, t46);
			if (if_block4) if_block4.m(div13, null);
			append(div15, t47);
			append(div15, div14);
			if (if_block5) if_block5.m(div14, null);
			append(div18, t48);
			append(div18, div17);
			mount_component(offsetbutton3, div17, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen(video, "loadedmetadata", /*loadedmetadata_handler*/ ctx[39]),
					listen(select0, "change", /*select0_change_handler*/ ctx[42]),
					listen(select0, "change", /*handleVariantChange*/ ctx[32]),
					listen(input1, "change", /*input1_change_handler*/ ctx[44]),
					listen(input2, "change", /*input2_change_handler*/ ctx[46]),
					listen(input3, "change", /*handleAddPhoto*/ ctx[26]),
					listen(input4, "change", /*handleAddVideo*/ ctx[28])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			const mediaquery_changes = {};

			if (dirty[1] & /*$$scope, matches*/ 1610612736) {
				mediaquery_changes.$$scope = { dirty, ctx };
			}

			mediaquery.$set(mediaquery_changes);

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div1, t1);
				}
			}

			if ((!current || dirty[0] & /*product*/ 4) && t3_value !== (t3_value = /*product*/ ctx[2].title + "")) set_data(t3, t3_value);

			if (current_block_type_1 === (current_block_type_1 = select_block_type_1(ctx, dirty)) && if_block1) {
				if_block1.p(ctx, dirty);
			} else {
				if_block1.d(1);
				if_block1 = current_block_type_1(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(h20, null);
				}
			}

			if ((!current || dirty[0] & /*product*/ 4) && raw_value !== (raw_value = /*product*/ ctx[2].descriptionHtml + "")) p.innerHTML = raw_value;;

			if (dirty[0] & /*product*/ 4) {
				each_value = /*product*/ ctx[2].variants.edges;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty[0] & /*variantSelected, product*/ 36) {
				select_option(select0, /*variantSelected*/ ctx[5]);
			}

			if (dirty[0] & /*cartoonize*/ 512) {
				input1.checked = input1.__value === /*cartoonize*/ ctx[9];
			}

			if (dirty[0] & /*cartoonize*/ 512) {
				input2.checked = input2.__value === /*cartoonize*/ ctx[9];
			}

			const offsetbutton0_changes = {};

			if (dirty[1] & /*$$scope*/ 1073741824) {
				offsetbutton0_changes.$$scope = { dirty, ctx };
			}

			offsetbutton0.$set(offsetbutton0_changes);
			const offsetbutton1_changes = {};
			if (dirty[0] & /*photoButtonDisabled*/ 4096) offsetbutton1_changes.disabled = /*photoButtonDisabled*/ ctx[12];

			if (dirty[1] & /*$$scope*/ 1073741824) {
				offsetbutton1_changes.$$scope = { dirty, ctx };
			}

			offsetbutton1.$set(offsetbutton1_changes);

			if (/*photoUrl*/ ctx[14]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);

					if (dirty[0] & /*photoUrl*/ 16384) {
						transition_in(if_block2, 1);
					}
				} else {
					if_block2 = create_if_block_4(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div8, null);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}

			if (/*showPhotoUploading*/ ctx[13]) {
				if (if_block3) {
					if (dirty[0] & /*showPhotoUploading*/ 8192) {
						transition_in(if_block3, 1);
					}
				} else {
					if_block3 = create_if_block_3(ctx);
					if_block3.c();
					transition_in(if_block3, 1);
					if_block3.m(div9, null);
				}
			} else if (if_block3) {
				group_outros();

				transition_out(if_block3, 1, 1, () => {
					if_block3 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*videoFileDisabled*/ 65536) {
				input4.disabled = /*videoFileDisabled*/ ctx[16];
			}

			const offsetbutton2_changes = {};
			if (dirty[0] & /*videoButtonDisabled*/ 262144) offsetbutton2_changes.disabled = /*videoButtonDisabled*/ ctx[18];

			if (dirty[1] & /*$$scope*/ 1073741824) {
				offsetbutton2_changes.$$scope = { dirty, ctx };
			}

			offsetbutton2.$set(offsetbutton2_changes);

			if (/*videoUrl*/ ctx[20]) {
				if (if_block4) {
					if_block4.p(ctx, dirty);

					if (dirty[0] & /*videoUrl*/ 1048576) {
						transition_in(if_block4, 1);
					}
				} else {
					if_block4 = create_if_block_2(ctx);
					if_block4.c();
					transition_in(if_block4, 1);
					if_block4.m(div13, null);
				}
			} else if (if_block4) {
				group_outros();

				transition_out(if_block4, 1, 1, () => {
					if_block4 = null;
				});

				check_outros();
			}

			if (/*showVideoUploading*/ ctx[19]) {
				if (if_block5) {
					if (dirty[0] & /*showVideoUploading*/ 524288) {
						transition_in(if_block5, 1);
					}
				} else {
					if_block5 = create_if_block_1(ctx);
					if_block5.c();
					transition_in(if_block5, 1);
					if_block5.m(div14, null);
				}
			} else if (if_block5) {
				group_outros();

				transition_out(if_block5, 1, 1, () => {
					if_block5 = null;
				});

				check_outros();
			}

			const offsetbutton3_changes = {};
			if (dirty[0] & /*addToCartDisabled*/ 4194304) offsetbutton3_changes.disabled = /*addToCartDisabled*/ ctx[22];

			if (dirty[1] & /*$$scope*/ 1073741824) {
				offsetbutton3_changes.$$scope = { dirty, ctx };
			}

			offsetbutton3.$set(offsetbutton3_changes);
		},
		i(local) {
			if (current) return;
			transition_in(mediaquery.$$.fragment, local);
			transition_in(offsetbutton0.$$.fragment, local);
			transition_in(offsetbutton1.$$.fragment, local);
			transition_in(if_block2);
			transition_in(if_block3);
			transition_in(offsetbutton2.$$.fragment, local);
			transition_in(if_block4);
			transition_in(if_block5);
			transition_in(offsetbutton3.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(mediaquery.$$.fragment, local);
			transition_out(offsetbutton0.$$.fragment, local);
			transition_out(offsetbutton1.$$.fragment, local);
			transition_out(if_block2);
			transition_out(if_block3);
			transition_out(offsetbutton2.$$.fragment, local);
			transition_out(if_block4);
			transition_out(if_block5);
			transition_out(offsetbutton3.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div20);
			destroy_component(mediaquery);
			if_block0.d();
			/*video_binding*/ ctx[40](null);
			if_block1.d();
			destroy_each(each_blocks, detaching);
			/*select0_binding*/ ctx[41](null);
			/*input0_binding*/ ctx[43](null);
			/*select1_binding*/ ctx[47](null);
			destroy_component(offsetbutton0);
			/*input3_binding*/ ctx[48](null);
			/*offsetbutton1_binding*/ ctx[49](null);
			destroy_component(offsetbutton1);
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			/*input4_binding*/ ctx[50](null);
			/*offsetbutton2_binding*/ ctx[51](null);
			destroy_component(offsetbutton2);
			if (if_block4) if_block4.d();
			if (if_block5) if_block5.d();
			/*offsetbutton3_binding*/ ctx[52](null);
			destroy_component(offsetbutton3);
			binding_group.r();
			mounted = false;
			run_all(dispose);
		}
	};
}

// (164:6) {#if matches}
function create_if_block_9(ctx) {
	let img0;
	let img0_src_value;
	let t;
	let img1;
	let img1_src_value;

	return {
		c() {
			img0 = element("img");
			t = space();
			img1 = element("img");
			if (!src_url_equal(img0.src, img0_src_value = "/img/cat_landing.png")) attr(img0, "src", img0_src_value);
			attr(img0, "alt", "");
			set_style(img0, "position", "absolute");
			set_style(img0, "left", "0");
			set_style(img0, "width", "260px");
			if (!src_url_equal(img1.src, img1_src_value = "/img/dog_landing.png")) attr(img1, "src", img1_src_value);
			attr(img1, "alt", "");
			set_style(img1, "position", "absolute");
			set_style(img1, "right", "0");
			set_style(img1, "bottom", "0");
			set_style(img1, "width", "260px");
		},
		m(target, anchor) {
			insert(target, img0, anchor);
			insert(target, t, anchor);
			insert(target, img1, anchor);
		},
		d(detaching) {
			if (detaching) detach(img0);
			if (detaching) detach(t);
			if (detaching) detach(img1);
		}
	};
}

// (163:4) <MediaQuery query="only screen and (min-width: 1900px)" let:matches>
function create_default_slot_6(ctx) {
	let if_block_anchor;
	let if_block = /*matches*/ ctx[60] && create_if_block_9(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*matches*/ ctx[60]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block_9(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (181:8) {:else}
function create_else_block_1(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			attr(img, "class", "main-img svelte-13yqyyf");
			if (!src_url_equal(img.src, img_src_value = /*imageSrc*/ ctx[0])) attr(img, "src", img_src_value);
			attr(img, "alt", "");
		},
		m(target, anchor) {
			insert(target, img, anchor);
			/*img_binding_1*/ ctx[38](img);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*imageSrc*/ 1 && !src_url_equal(img.src, img_src_value = /*imageSrc*/ ctx[0])) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			/*img_binding_1*/ ctx[38](null);
		}
	};
}

// (171:8) {#if product.images.edges}
function create_if_block_7(ctx) {
	let img;
	let img_src_value;
	let t;
	let if_block_anchor;
	let if_block = /*product*/ ctx[2].images.edges.length > 1 && create_if_block_8(ctx);

	return {
		c() {
			img = element("img");
			t = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(img, "class", "main-img svelte-13yqyyf");
			if (!src_url_equal(img.src, img_src_value = /*product*/ ctx[2].images.edges[0].node.transformedSrc)) attr(img, "src", img_src_value);
			attr(img, "alt", "");
		},
		m(target, anchor) {
			insert(target, img, anchor);
			/*img_binding*/ ctx[37](img);
			insert(target, t, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*product*/ 4 && !src_url_equal(img.src, img_src_value = /*product*/ ctx[2].images.edges[0].node.transformedSrc)) {
				attr(img, "src", img_src_value);
			}

			if (/*product*/ ctx[2].images.edges.length > 1) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_8(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			/*img_binding*/ ctx[37](null);
			if (detaching) detach(t);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (173:10) {#if product.images.edges.length > 1}
function create_if_block_8(ctx) {
	let div;
	let each_value_1 = /*product*/ ctx[2].images.edges;
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "thumb-container svelte-13yqyyf");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*product*/ 4 | dirty[1] & /*handleImageSwap*/ 16) {
				each_value_1 = /*product*/ ctx[2].images.edges;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (175:14) {#each product.images.edges as image}
function create_each_block_1(ctx) {
	let img;
	let img_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			img = element("img");
			attr(img, "class", "thumb-img svelte-13yqyyf");
			if (!src_url_equal(img.src, img_src_value = /*image*/ ctx[57].node.transformedSrc)) attr(img, "src", img_src_value);
			attr(img, "alt", "");
		},
		m(target, anchor) {
			insert(target, img, anchor);

			if (!mounted) {
				dispose = listen(img, "click", function () {
					if (is_function(/*handleImageSwap*/ ctx[35](/*image*/ ctx[57].node.transformedSrc))) /*handleImageSwap*/ ctx[35](/*image*/ ctx[57].node.transformedSrc).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*product*/ 4 && !src_url_equal(img.src, img_src_value = /*image*/ ctx[57].node.transformedSrc)) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			mounted = false;
			dispose();
		}
	};
}

// (196:10) {:else}
function create_else_block(ctx) {
	let t0;
	let t1;

	return {
		c() {
			t0 = text("$");
			t1 = text(/*variantPrice*/ ctx[6]);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*variantPrice*/ 64) set_data(t1, /*variantPrice*/ ctx[6]);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (193:10) {#if variantComparePrice}
function create_if_block_6(ctx) {
	let span0;
	let t0;
	let t1;
	let t2;
	let span1;
	let t3;
	let t4;

	return {
		c() {
			span0 = element("span");
			t0 = text("$");
			t1 = text(/*variantComparePrice*/ ctx[7]);
			t2 = space();
			span1 = element("span");
			t3 = text("$");
			t4 = text(/*variantPrice*/ ctx[6]);
			attr(span0, "class", "compare-price svelte-13yqyyf");
			attr(span1, "class", "red svelte-13yqyyf");
		},
		m(target, anchor) {
			insert(target, span0, anchor);
			append(span0, t0);
			append(span0, t1);
			insert(target, t2, anchor);
			insert(target, span1, anchor);
			append(span1, t3);
			append(span1, t4);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*variantComparePrice*/ 128) set_data(t1, /*variantComparePrice*/ ctx[7]);
			if (dirty[0] & /*variantPrice*/ 64) set_data(t4, /*variantPrice*/ ctx[6]);
		},
		d(detaching) {
			if (detaching) detach(span0);
			if (detaching) detach(t2);
			if (detaching) detach(span1);
		}
	};
}

// (208:14) {#if variant.node.availableForSale}
function create_if_block_5(ctx) {
	let option;
	let t_value = /*variant*/ ctx[24].node.title + "";
	let t;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*variant*/ ctx[24].node;
			option.value = option.__value;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*product*/ 4 && t_value !== (t_value = /*variant*/ ctx[24].node.title + "")) set_data(t, t_value);

			if (dirty[0] & /*product*/ 4 && option_value_value !== (option_value_value = /*variant*/ ctx[24].node)) {
				option.__value = option_value_value;
				option.value = option.__value;
			}
		},
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

// (207:12) {#each product.variants.edges as variant}
function create_each_block(ctx) {
	let if_block_anchor;
	let if_block = /*variant*/ ctx[24].node.availableForSale && create_if_block_5(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*variant*/ ctx[24].node.availableForSale) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_5(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (238:12) <OffsetButton color="#ff89de" on:click={handleModalOpen} customStyle="margin-top:16px;">
function create_default_slot_5(ctx) {
	let t;

	return {
		c() {
			t = text("Preview");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (252:14) <OffsetButton color="#5cbdf9" on:click={addPhoto} customStyle="margin-top:16px; margin-right: 2rem;" bind:this="{photoButton}" disabled="{photoButtonDisabled}">
function create_default_slot_4(ctx) {
	let t;

	return {
		c() {
			t = text("Upload My Photo");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (253:14) {#if photoUrl}
function create_if_block_4(ctx) {
	let offsetbutton;
	let current;

	offsetbutton = new OffsetButton({
			props: {
				color: "green",
				customStyle: "margin-top:16px;",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	offsetbutton.$on("click", /*handlePhotoPreviewClick*/ ctx[30]);

	return {
		c() {
			create_component(offsetbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(offsetbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const offsetbutton_changes = {};

			if (dirty[1] & /*$$scope*/ 1073741824) {
				offsetbutton_changes.$$scope = { dirty, ctx };
			}

			offsetbutton.$set(offsetbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(offsetbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(offsetbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(offsetbutton, detaching);
		}
	};
}

// (254:16) <OffsetButton color="green" on:click={handlePhotoPreviewClick} customStyle="margin-top:16px;">
function create_default_slot_3(ctx) {
	let t;

	return {
		c() {
			t = text("Photo Uploaded");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (258:14) {#if showPhotoUploading}
function create_if_block_3(ctx) {
	let loadinganimation;
	let current;
	loadinganimation = new LoadingAnimation({});

	return {
		c() {
			create_component(loadinganimation.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loadinganimation, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(loadinganimation.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loadinganimation.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loadinganimation, detaching);
		}
	};
}

// (275:14) <OffsetButton color="#5cbdf9" on:click={addVideo} customStyle="margin-top:16px; margin-right: 2rem;" bind:this="{videoButton}" disabled="{videoButtonDisabled}">
function create_default_slot_2(ctx) {
	let t;

	return {
		c() {
			t = text("Upload My Video");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (276:14) {#if videoUrl}
function create_if_block_2(ctx) {
	let offsetbutton;
	let current;

	offsetbutton = new OffsetButton({
			props: {
				color: "green",
				customStyle: "margin-top:16px;",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	offsetbutton.$on("click", /*handleVideoPreviewClick*/ ctx[31]);

	return {
		c() {
			create_component(offsetbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(offsetbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const offsetbutton_changes = {};

			if (dirty[1] & /*$$scope*/ 1073741824) {
				offsetbutton_changes.$$scope = { dirty, ctx };
			}

			offsetbutton.$set(offsetbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(offsetbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(offsetbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(offsetbutton, detaching);
		}
	};
}

// (277:16) <OffsetButton color="green" on:click={handleVideoPreviewClick} customStyle="margin-top:16px;">
function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text("Video Uploaded");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (281:14) {#if showVideoUploading}
function create_if_block_1(ctx) {
	let loadinganimation;
	let current;
	loadinganimation = new LoadingAnimation({});

	return {
		c() {
			create_component(loadinganimation.$$.fragment);
		},
		m(target, anchor) {
			mount_component(loadinganimation, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(loadinganimation.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loadinganimation.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(loadinganimation, detaching);
		}
	};
}

// (289:10) <OffsetButton color="#f2a240" on:click={addToCheckout} width="55%" customStyle="min-width: 230px; margin-bottom: 25px;" bind:this="{addToCartButton}" disabled="{addToCartDisabled}">
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Add To Cart");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let navbar;
	let t0;
	let t1;
	let footer;
	let t2;
	let modal_1;
	let current;
	navbar = new NavBar({});
	let if_block = /*product*/ ctx[2] && create_if_block(ctx);
	footer = new Footer({});

	modal_1 = new Modal({
			props: {
				show: /*$modal*/ ctx[23],
				closeButton: true
			}
		});

	modal_1.$on("closed", /*handleModalClosed*/ ctx[34]);

	return {
		c() {
			create_component(navbar.$$.fragment);
			t0 = space();
			if (if_block) if_block.c();
			t1 = space();
			create_component(footer.$$.fragment);
			t2 = space();
			create_component(modal_1.$$.fragment);
		},
		m(target, anchor) {
			mount_component(navbar, target, anchor);
			insert(target, t0, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, t1, anchor);
			mount_component(footer, target, anchor);
			insert(target, t2, anchor);
			mount_component(modal_1, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*product*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty[0] & /*product*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(t1.parentNode, t1);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			const modal_1_changes = {};
			if (dirty[0] & /*$modal*/ 8388608) modal_1_changes.show = /*$modal*/ ctx[23];
			modal_1.$set(modal_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(navbar.$$.fragment, local);
			transition_in(if_block);
			transition_in(footer.$$.fragment, local);
			transition_in(modal_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(navbar.$$.fragment, local);
			transition_out(if_block);
			transition_out(footer.$$.fragment, local);
			transition_out(modal_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(navbar, detaching);
			if (detaching) detach(t0);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t1);
			destroy_component(footer, detaching);
			if (detaching) detach(t2);
			destroy_component(modal_1, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $modal;
	component_subscribe($$self, modal, $$value => $$invalidate(23, $modal = $$value));
	let { type } = $$props;
	let imageSrc;

	switch (type) {
		case "shirt":
			imageSrc = "/img/tshirt_product.png";
			break;
		case "mug":
			imageSrc = "/img/mug_product.png";
			break;
		case "photo":
			imageSrc = "/img/photo_product.png";
			break;
		default:
			imageSrc = "/img/tshirt_product.png";
			break;
	}

	let vid;

	onMount(async () => {
		// Get the product detail
		let res = await api.getProductByType(type);

		$$invalidate(2, product = res.data.products.edges[0].node);
		$$invalidate(6, variantPrice = product.variants.edges[0].node.price);
		$$invalidate(7, variantComparePrice = product.variants.edges[0].node.compareAtPrice);
	});

	let product;
	let checkoutId = getSavedCheckoutId();
	let mainImage;
	let theme;
	let variant;
	let variantSelected;
	let variantPrice;
	let variantComparePrice;
	let qty;
	let cartoonize = "yes";
	let photoFile;
	let photoButton;
	let photoButtonDisabled = true;
	let showPhotoUploading = false;
	let photoUrl;
	let videoFile;
	let videoFileDisabled = true;
	let videoButton;
	let videoButtonDisabled = true;
	let showVideoUploading = false;
	let videoUrl;
	let addToCartButton;
	let addToCartDisabled = true;

	async function createCheckout() {
		let checkoutDeets = await api.createCheckout();
		checkoutId = checkoutDeets.data.checkoutCreate.checkout.id;
	}

	async function addToCheckout() {
		try {
			let checkout = await api.getCheckout(checkoutId);

			if (checkout.data.node.order) {
				await createCheckout();
				setSavedCheckoutId(checkoutId);
			}
		} catch(err) {
			await createCheckout();
			setSavedCheckoutId(checkoutId);
		}

		if (!cartoonize) {
			alert("Please select whether or not you would like to cartoonize your photo.");
			return;
		}

		if (photoUrl && videoUrl) {
			await api.addToCheckout(checkoutId, variantSelected.id, qty.value, cartoonize, theme.value, photoUrl, videoUrl);
			$$invalidate(10, photoFile.value = '', photoFile);
			$$invalidate(14, photoUrl = null);
			$$invalidate(15, videoFile.value = '', videoFile);
			$$invalidate(20, videoUrl = null);
			window.location.href = '/cart';
		} else {
			alert("Please upload a photo and video.");
		}
	}

	function handleAddPhoto() {
		$$invalidate(12, photoButtonDisabled = false);
	}

	async function addPhoto() {
		$$invalidate(13, showPhotoUploading = true);
		$$invalidate(14, photoUrl = await upload.addPhoto(photoFile.files));
		$$invalidate(13, showPhotoUploading = false);
		$$invalidate(16, videoFileDisabled = false);
	}

	function handleAddVideo() {
		$$invalidate(18, videoButtonDisabled = false);
	}

	async function addVideo() {
		$$invalidate(19, showVideoUploading = true);
		$$invalidate(20, videoUrl = await upload.addVideo(videoFile.files));
		$$invalidate(19, showVideoUploading = false);
		$$invalidate(22, addToCartDisabled = false);
	}

	function handlePhotoPreviewClick() {
		modal.set(bind(ImagePreview, { imageUrl: photoUrl }));
	}

	function handleVideoPreviewClick() {
		modal.set(bind(VideoPreview, { videoUrl }));
	}

	async function handleVariantChange() {
		$$invalidate(6, variantPrice = variantSelected.price);
		$$invalidate(7, variantComparePrice = variantSelected.compareAtPrice);
	}

	async function handleModalOpen() {
		modal.set(bind(ThemePreview, { theme: theme.value }));
	}

	async function handleModalClosed() {
		modal.set();
	}

	async function handleImageSwap(img) {
		$$invalidate(3, mainImage.src = img, mainImage);
	}

	const $$binding_groups = [[]];

	function img_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			mainImage = $$value;
			$$invalidate(3, mainImage);
		});
	}

	function img_binding_1($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			mainImage = $$value;
			$$invalidate(3, mainImage);
		});
	}

	const loadedmetadata_handler = () => $$invalidate(1, vid.currentTime = 0.5, vid);

	function video_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			vid = $$value;
			$$invalidate(1, vid);
		});
	}

	function select0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			variant = $$value;
			$$invalidate(24, variant);
		});
	}

	function select0_change_handler() {
		variantSelected = select_value(this);
		$$invalidate(5, variantSelected);
		$$invalidate(2, product);
	}

	function input0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			qty = $$value;
			$$invalidate(8, qty);
		});
	}

	function input1_change_handler() {
		cartoonize = this.__value;
		$$invalidate(9, cartoonize);
	}

	function input2_change_handler() {
		cartoonize = this.__value;
		$$invalidate(9, cartoonize);
	}

	function select1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			theme = $$value;
			$$invalidate(4, theme);
		});
	}

	function input3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			photoFile = $$value;
			$$invalidate(10, photoFile);
		});
	}

	function offsetbutton1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			photoButton = $$value;
			$$invalidate(11, photoButton);
		});
	}

	function input4_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			videoFile = $$value;
			$$invalidate(15, videoFile);
		});
	}

	function offsetbutton2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			videoButton = $$value;
			$$invalidate(17, videoButton);
		});
	}

	function offsetbutton3_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			addToCartButton = $$value;
			$$invalidate(21, addToCartButton);
		});
	}

	$$self.$$set = $$props => {
		if ('type' in $$props) $$invalidate(36, type = $$props.type);
	};

	return [
		imageSrc,
		vid,
		product,
		mainImage,
		theme,
		variantSelected,
		variantPrice,
		variantComparePrice,
		qty,
		cartoonize,
		photoFile,
		photoButton,
		photoButtonDisabled,
		showPhotoUploading,
		photoUrl,
		videoFile,
		videoFileDisabled,
		videoButton,
		videoButtonDisabled,
		showVideoUploading,
		videoUrl,
		addToCartButton,
		addToCartDisabled,
		$modal,
		variant,
		addToCheckout,
		handleAddPhoto,
		addPhoto,
		handleAddVideo,
		addVideo,
		handlePhotoPreviewClick,
		handleVideoPreviewClick,
		handleVariantChange,
		handleModalOpen,
		handleModalClosed,
		handleImageSwap,
		type,
		img_binding,
		img_binding_1,
		loadedmetadata_handler,
		video_binding,
		select0_binding,
		select0_change_handler,
		input0_binding,
		input1_change_handler,
		$$binding_groups,
		input2_change_handler,
		select1_binding,
		input3_binding,
		offsetbutton1_binding,
		input4_binding,
		offsetbutton2_binding,
		offsetbutton3_binding
	];
}

class Product extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { type: 36 }, null, [-1, -1]);
	}
}

export default Product;