import './Footer.svelte.css';
/* src/components/Footer.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { navigate } from "../../_snowpack/pkg/svelte-routing.js";
import OffsetButton from "./OffsetButtons.svelte.js";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Affiliate Application");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let div7;
	let div0;
	let t1;
	let div6;
	let div1;
	let t5;
	let div2;
	let t7;
	let div3;
	let t9;
	let div4;
	let t11;
	let div5;
	let offsetbutton;
	let current;
	let mounted;
	let dispose;

	offsetbutton = new OffsetButton({
			props: {
				color: "#f2a240",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div7 = element("div");
			div0 = element("div");

			div0.innerHTML = `<a href="https://www.instagram.com/mypetar" target="_blank"><img src="/img/ig.png" alt="Instagram" class="svelte-1zbjd2"/></a> 
    <a href="https://vm.tiktok.com/ZMdqerjWp/" target="_blank"><img src="/img/tt.png" alt="Instagram" class="svelte-1zbjd2"/></a>`;

			t1 = space();
			div6 = element("div");
			div1 = element("div");
			div1.textContent = `© COPYRIGHT ${/*currentYear*/ ctx[0]} PRO EXP MEDIA INC.`;
			t5 = space();
			div2 = element("div");
			div2.innerHTML = `<a href="/terms-and-conditions" class="svelte-1zbjd2">TERMS AND CONDITIONS</a>`;
			t7 = space();
			div3 = element("div");
			div3.innerHTML = `<a href="/return-policy" class="svelte-1zbjd2">RETURN POLICY</a>`;
			t9 = space();
			div4 = element("div");
			div4.innerHTML = `<a href="/privacy-policy" class="svelte-1zbjd2">PRIVACY POLICY</a>`;
			t11 = space();
			div5 = element("div");
			create_component(offsetbutton.$$.fragment);
			attr(div0, "class", "social-media svelte-1zbjd2");
			attr(div1, "class", "svelte-1zbjd2");
			attr(div2, "class", "svelte-1zbjd2");
			attr(div3, "class", "svelte-1zbjd2");
			attr(div4, "class", "svelte-1zbjd2");
			attr(div5, "class", "affiliate-container svelte-1zbjd2");
			attr(div6, "class", "legal svelte-1zbjd2");
			attr(div7, "class", "container svelte-1zbjd2");
		},
		m(target, anchor) {
			insert(target, div7, anchor);
			append(div7, div0);
			append(div7, t1);
			append(div7, div6);
			append(div6, div1);
			append(div6, t5);
			append(div6, div2);
			append(div6, t7);
			append(div6, div3);
			append(div6, t9);
			append(div6, div4);
			append(div6, t11);
			append(div6, div5);
			mount_component(offsetbutton, div5, null);
			current = true;

			if (!mounted) {
				dispose = listen(div5, "click", /*click_handler*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const offsetbutton_changes = {};

			if (dirty & /*$$scope*/ 4) {
				offsetbutton_changes.$$scope = { dirty, ctx };
			}

			offsetbutton.$set(offsetbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(offsetbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(offsetbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div7);
			destroy_component(offsetbutton);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self) {
	let currentYear = new Date().getFullYear();
	const click_handler = () => navigate("/affiliate");
	return [currentYear, click_handler];
}

class Footer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Footer;