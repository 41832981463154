import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import './AffiliateForm.svelte.css';
/* src/components/AffiliateForm.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_input_value,
	set_style,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

import { createEventDispatcher } from "../../_snowpack/pkg/svelte.js";

function create_fragment(ctx) {
	let form;
	let div0;
	let label0;
	let t1;
	let input0;
	let t2;
	let div1;
	let label1;
	let t4;
	let input1;
	let t5;
	let input2;
	let t6;
	let input3;
	let t7;
	let input4;
	let t8;
	let input5;
	let t9;
	let div2;
	let label2;
	let t11;
	let input6;
	let t12;
	let div3;
	let label3;
	let t14;
	let input7;
	let t15;
	let input8;
	let t16;
	let div4;
	let label4;
	let t18;
	let input9;
	let t19;
	let div5;
	let t23;
	let input11;
	let mounted;
	let dispose;

	return {
		c() {
			form = element("form");
			div0 = element("div");
			label0 = element("label");
			label0.textContent = "Name";
			t1 = space();
			input0 = element("input");
			t2 = space();
			div1 = element("div");
			label1 = element("label");
			label1.textContent = "Address";
			t4 = space();
			input1 = element("input");
			t5 = space();
			input2 = element("input");
			t6 = space();
			input3 = element("input");
			t7 = space();
			input4 = element("input");
			t8 = space();
			input5 = element("input");
			t9 = space();
			div2 = element("div");
			label2 = element("label");
			label2.textContent = "Email Address";
			t11 = space();
			input6 = element("input");
			t12 = space();
			div3 = element("div");
			label3 = element("label");
			label3.textContent = "Social Handles (Please include at least one)";
			t14 = space();
			input7 = element("input");
			t15 = space();
			input8 = element("input");
			t16 = space();
			div4 = element("div");
			label4 = element("label");
			label4.textContent = "Payouts are done via Paypal. Please include your Paypal address";
			t18 = space();
			input9 = element("input");
			t19 = space();
			div5 = element("div");

			div5.innerHTML = `<label style="font-weight: 400; font-size: 0.9em; margin-right: 12px; display: inline; flex-basis: auto;" for="agree" class="svelte-qcclo2">I have read and agree to the <a href="/affiliate-agreement">MyPetAR Affiliate Agreement</a></label> 
    <input required="" style="margin: 0;" id="agree" type="checkbox" class="svelte-qcclo2"/>`;

			t23 = space();
			input11 = element("input");
			attr(label0, "class", "required svelte-qcclo2");
			attr(label0, "for", "name");
			input0.required = true;
			attr(input0, "class", "half svelte-qcclo2");
			attr(input0, "type", "text");
			attr(input0, "placeholder", "Name");
			attr(input0, "id", "name");
			attr(div0, "class", "svelte-qcclo2");
			attr(label1, "class", "required svelte-qcclo2");
			attr(label1, "for", "shop-street-1");
			input1.required = true;
			attr(input1, "class", "full svelte-qcclo2");
			attr(input1, "id", "shop-street-1");
			attr(input1, "type", "text");
			attr(input1, "placeholder", "Street Address");
			attr(input2, "class", "full svelte-qcclo2");
			attr(input2, "type", "text");
			attr(input2, "placeholder", "Street Address Line 2");
			input3.required = true;
			attr(input3, "class", "half svelte-qcclo2");
			attr(input3, "type", "text");
			attr(input3, "placeholder", "City");
			input4.required = true;
			attr(input4, "class", "half svelte-qcclo2");
			attr(input4, "type", "text");
			attr(input4, "placeholder", "State / Province");
			input5.required = true;
			attr(input5, "class", "full svelte-qcclo2");
			attr(input5, "type", "text");
			attr(input5, "placeholder", "Postal / Zip Code");
			attr(div1, "class", "svelte-qcclo2");
			attr(label2, "class", "required svelte-qcclo2");
			attr(label2, "for", "email");
			input6.required = true;
			attr(input6, "class", "half svelte-qcclo2");
			attr(input6, "id", "email");
			attr(input6, "type", "email");
			attr(input6, "placeholder", "Email");
			attr(div2, "class", "svelte-qcclo2");
			attr(label3, "for", "instagram");
			attr(label3, "class", "required svelte-qcclo2");
			attr(input7, "class", "initial half svelte-qcclo2");
			attr(input7, "id", "instagram");
			attr(input7, "type", "text");
			attr(input7, "placeholder", "Instagram");
			attr(input8, "class", "half svelte-qcclo2");
			attr(input8, "id", "tiktok");
			attr(input8, "type", "text");
			attr(input8, "placeholder", "TikTok");
			attr(div3, "class", "svelte-qcclo2");
			attr(label4, "for", "referral");
			attr(label4, "class", "required svelte-qcclo2");
			input9.required = true;
			attr(input9, "class", "full svelte-qcclo2");
			attr(input9, "id", "referral");
			attr(input9, "type", "text");
			attr(input9, "placeholder", "");
			attr(div4, "class", "svelte-qcclo2");
			set_style(div5, "justify-content", "end");
			attr(div5, "class", "svelte-qcclo2");
			attr(input11, "class", "submit svelte-qcclo2");
			attr(input11, "type", "submit");
			input11.value = "SUBMIT";
			attr(form, "class", "svelte-qcclo2");
		},
		m(target, anchor) {
			insert(target, form, anchor);
			append(form, div0);
			append(div0, label0);
			append(div0, t1);
			append(div0, input0);
			set_input_value(input0, /*data*/ ctx[0].name);
			append(form, t2);
			append(form, div1);
			append(div1, label1);
			append(div1, t4);
			append(div1, input1);
			set_input_value(input1, /*data*/ ctx[0].address_line_1);
			append(div1, t5);
			append(div1, input2);
			set_input_value(input2, /*data*/ ctx[0].address_line_2);
			append(div1, t6);
			append(div1, input3);
			set_input_value(input3, /*data*/ ctx[0].address_city);
			append(div1, t7);
			append(div1, input4);
			set_input_value(input4, /*data*/ ctx[0].address_state);
			append(div1, t8);
			append(div1, input5);
			set_input_value(input5, /*data*/ ctx[0].address_postal);
			append(form, t9);
			append(form, div2);
			append(div2, label2);
			append(div2, t11);
			append(div2, input6);
			set_input_value(input6, /*data*/ ctx[0].email);
			append(form, t12);
			append(form, div3);
			append(div3, label3);
			append(div3, t14);
			append(div3, input7);
			set_input_value(input7, /*data*/ ctx[0].instagram);
			append(div3, t15);
			append(div3, input8);
			set_input_value(input8, /*data*/ ctx[0].tiktok);
			append(form, t16);
			append(form, div4);
			append(div4, label4);
			append(div4, t18);
			append(div4, input9);
			set_input_value(input9, /*data*/ ctx[0].paypal);
			append(form, t19);
			append(form, div5);
			append(form, t23);
			append(form, input11);

			if (!mounted) {
				dispose = [
					listen(input0, "input", /*input0_input_handler*/ ctx[3]),
					listen(input1, "input", /*input1_input_handler*/ ctx[4]),
					listen(input2, "input", /*input2_input_handler*/ ctx[5]),
					listen(input3, "input", /*input3_input_handler*/ ctx[6]),
					listen(input4, "input", /*input4_input_handler*/ ctx[7]),
					listen(input5, "input", /*input5_input_handler*/ ctx[8]),
					listen(input6, "input", /*input6_input_handler*/ ctx[9]),
					listen(input7, "input", /*input7_input_handler*/ ctx[10]),
					listen(input8, "input", /*input8_input_handler*/ ctx[11]),
					listen(input9, "input", /*input9_input_handler*/ ctx[12]),
					listen(form, "submit", prevent_default(/*onSubmit*/ ctx[1]))
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*data*/ 1 && input0.value !== /*data*/ ctx[0].name) {
				set_input_value(input0, /*data*/ ctx[0].name);
			}

			if (dirty & /*data*/ 1 && input1.value !== /*data*/ ctx[0].address_line_1) {
				set_input_value(input1, /*data*/ ctx[0].address_line_1);
			}

			if (dirty & /*data*/ 1 && input2.value !== /*data*/ ctx[0].address_line_2) {
				set_input_value(input2, /*data*/ ctx[0].address_line_2);
			}

			if (dirty & /*data*/ 1 && input3.value !== /*data*/ ctx[0].address_city) {
				set_input_value(input3, /*data*/ ctx[0].address_city);
			}

			if (dirty & /*data*/ 1 && input4.value !== /*data*/ ctx[0].address_state) {
				set_input_value(input4, /*data*/ ctx[0].address_state);
			}

			if (dirty & /*data*/ 1 && input5.value !== /*data*/ ctx[0].address_postal) {
				set_input_value(input5, /*data*/ ctx[0].address_postal);
			}

			if (dirty & /*data*/ 1 && input6.value !== /*data*/ ctx[0].email) {
				set_input_value(input6, /*data*/ ctx[0].email);
			}

			if (dirty & /*data*/ 1 && input7.value !== /*data*/ ctx[0].instagram) {
				set_input_value(input7, /*data*/ ctx[0].instagram);
			}

			if (dirty & /*data*/ 1 && input8.value !== /*data*/ ctx[0].tiktok) {
				set_input_value(input8, /*data*/ ctx[0].tiktok);
			}

			if (dirty & /*data*/ 1 && input9.value !== /*data*/ ctx[0].paypal) {
				set_input_value(input9, /*data*/ ctx[0].paypal);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(form);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { onDone } = $$props;
	const dispatch = createEventDispatcher();

	let data = {
		name: "",
		address_line_1: "",
		address_line_2: "",
		address_city: "",
		address_state: "",
		address_postal: "",
		email: "",
		instagram: "",
		tiktok: "",
		paypal: ""
	};

	const onSubmit = async () => {
		if (!data.tiktok.replace(/\s+/g, '') && !data.instagram.replace(/\s+/g, '')) {
			alert("Please input at least one social handle.");
			return;
		}

		dispatch('load', { load: true });

		try {
			let res = await fetch(__SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL + "/affiliate_applications", {
				method: "POST",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data)
			});

			res = await res.json();
			dispatch('load', { load: false });

			if (res.status) {
				if (res.status === "OK") {
					onDone(false, res.message);
				} else {
					onDone(true, res.message);
				}
			} else {
				onDone(true, "Something went wrong. Try again later.");
			}
		} catch(err) {
			console.log(err);
			dispatch('load', { load: false });
			onDone(true, "Something went wrong. Try again later.");
		}
	};

	function input0_input_handler() {
		data.name = this.value;
		$$invalidate(0, data);
	}

	function input1_input_handler() {
		data.address_line_1 = this.value;
		$$invalidate(0, data);
	}

	function input2_input_handler() {
		data.address_line_2 = this.value;
		$$invalidate(0, data);
	}

	function input3_input_handler() {
		data.address_city = this.value;
		$$invalidate(0, data);
	}

	function input4_input_handler() {
		data.address_state = this.value;
		$$invalidate(0, data);
	}

	function input5_input_handler() {
		data.address_postal = this.value;
		$$invalidate(0, data);
	}

	function input6_input_handler() {
		data.email = this.value;
		$$invalidate(0, data);
	}

	function input7_input_handler() {
		data.instagram = this.value;
		$$invalidate(0, data);
	}

	function input8_input_handler() {
		data.tiktok = this.value;
		$$invalidate(0, data);
	}

	function input9_input_handler() {
		data.paypal = this.value;
		$$invalidate(0, data);
	}

	$$self.$$set = $$props => {
		if ('onDone' in $$props) $$invalidate(2, onDone = $$props.onDone);
	};

	return [
		data,
		onSubmit,
		onDone,
		input0_input_handler,
		input1_input_handler,
		input2_input_handler,
		input3_input_handler,
		input4_input_handler,
		input5_input_handler,
		input6_input_handler,
		input7_input_handler,
		input8_input_handler,
		input9_input_handler
	];
}

class AffiliateForm extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { onDone: 2 });
	}
}

export default AffiliateForm;