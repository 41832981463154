/* src/routing/Routes.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { isDevelopment } from "../util.js";
import { Router, Route } from "../../_snowpack/pkg/svelte-routing.js";
import Redirect from "./Redirect.svelte.js";
import Home from "../screens/Home.svelte.js";
import Front from "../screens/Front.svelte.js";
import Product from "../screens/Product.svelte.js";
import About from "../components/About.svelte.js";
import Cart from "../screens/Cart.svelte.js";
import TermsAndConditions from "../screens/TermsAndConditions.svelte.js";
import ReturnPolicy from "../screens/ReturnPolicy.svelte.js";
import PrivacyPolicy from "../screens/PrivacyPolicy.svelte.js";
import AffiliateScreen from "../screens/AffiliateScreen.svelte.js";
import AffiliateAgreement from "../screens/AffiliateAgreement.svelte.js";

function create_default_slot_10(ctx) {
	let front;
	let current;
	front = new Front({});

	return {
		c() {
			create_component(front.$$.fragment);
		},
		m(target, anchor) {
			mount_component(front, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(front.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(front.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(front, detaching);
		}
	};
}

// (23:2) <Route path="/products/:type" let:params>
function create_default_slot_9(ctx) {
	let product;
	let current;

	product = new Product({
			props: {
				type: encodeURIComponent(/*params*/ ctx[1].type)
			}
		});

	return {
		c() {
			create_component(product.$$.fragment);
		},
		m(target, anchor) {
			mount_component(product, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const product_changes = {};
			if (dirty & /*params*/ 2) product_changes.type = encodeURIComponent(/*params*/ ctx[1].type);
			product.$set(product_changes);
		},
		i(local) {
			if (current) return;
			transition_in(product.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(product.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(product, detaching);
		}
	};
}

// (26:2) <Route path="/terms-and-conditions">
function create_default_slot_8(ctx) {
	let termsandconditions;
	let current;
	termsandconditions = new TermsAndConditions({});

	return {
		c() {
			create_component(termsandconditions.$$.fragment);
		},
		m(target, anchor) {
			mount_component(termsandconditions, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(termsandconditions.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(termsandconditions.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(termsandconditions, detaching);
		}
	};
}

// (29:2) <Route path="/return-policy">
function create_default_slot_7(ctx) {
	let returnpolicy;
	let current;
	returnpolicy = new ReturnPolicy({});

	return {
		c() {
			create_component(returnpolicy.$$.fragment);
		},
		m(target, anchor) {
			mount_component(returnpolicy, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(returnpolicy.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(returnpolicy.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(returnpolicy, detaching);
		}
	};
}

// (32:2) <Route path="/privacy-policy">
function create_default_slot_6(ctx) {
	let privacypolicy;
	let current;
	privacypolicy = new PrivacyPolicy({});

	return {
		c() {
			create_component(privacypolicy.$$.fragment);
		},
		m(target, anchor) {
			mount_component(privacypolicy, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(privacypolicy.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(privacypolicy.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(privacypolicy, detaching);
		}
	};
}

// (35:2) <Route path="/affiliate">
function create_default_slot_5(ctx) {
	let affiliatescreen;
	let current;
	affiliatescreen = new AffiliateScreen({});

	return {
		c() {
			create_component(affiliatescreen.$$.fragment);
		},
		m(target, anchor) {
			mount_component(affiliatescreen, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(affiliatescreen.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(affiliatescreen.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(affiliatescreen, detaching);
		}
	};
}

// (38:2) <Route path="/affiliate-agreement">
function create_default_slot_4(ctx) {
	let affiliateagreement;
	let current;
	affiliateagreement = new AffiliateAgreement({});

	return {
		c() {
			create_component(affiliateagreement.$$.fragment);
		},
		m(target, anchor) {
			mount_component(affiliateagreement, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(affiliateagreement.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(affiliateagreement.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(affiliateagreement, detaching);
		}
	};
}

// (41:2) <Route path="/404">
function create_default_slot_3(ctx) {
	let t;

	return {
		c() {
			t = text("404");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (44:2) <Route path="/cart">
function create_default_slot_2(ctx) {
	let cart;
	let current;
	cart = new Cart({});

	return {
		c() {
			create_component(cart.$$.fragment);
		},
		m(target, anchor) {
			mount_component(cart, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(cart.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(cart.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(cart, detaching);
		}
	};
}

// (47:2) <Route path="/*">
function create_default_slot_1(ctx) {
	let redirect;
	let current;
	redirect = new Redirect({ props: { path: "/404" } });

	return {
		c() {
			create_component(redirect.$$.fragment);
		},
		m(target, anchor) {
			mount_component(redirect, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(redirect.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(redirect.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(redirect, detaching);
		}
	};
}

// (19:0) <Router url="{url}">
function create_default_slot(ctx) {
	let route0;
	let t0;
	let route1;
	let t1;
	let route2;
	let t2;
	let route3;
	let t3;
	let route4;
	let t4;
	let route5;
	let t5;
	let route6;
	let t6;
	let route7;
	let t7;
	let route8;
	let t8;
	let route9;
	let current;

	route0 = new Route({
			props: {
				path: "/",
				$$slots: { default: [create_default_slot_10] },
				$$scope: { ctx }
			}
		});

	route1 = new Route({
			props: {
				path: "/products/:type",
				$$slots: {
					default: [
						create_default_slot_9,
						({ params }) => ({ 1: params }),
						({ params }) => params ? 2 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	route2 = new Route({
			props: {
				path: "/terms-and-conditions",
				$$slots: { default: [create_default_slot_8] },
				$$scope: { ctx }
			}
		});

	route3 = new Route({
			props: {
				path: "/return-policy",
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			}
		});

	route4 = new Route({
			props: {
				path: "/privacy-policy",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			}
		});

	route5 = new Route({
			props: {
				path: "/affiliate",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			}
		});

	route6 = new Route({
			props: {
				path: "/affiliate-agreement",
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			}
		});

	route7 = new Route({
			props: {
				path: "/404",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			}
		});

	route8 = new Route({
			props: {
				path: "/cart",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	route9 = new Route({
			props: {
				path: "/*",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(route0.$$.fragment);
			t0 = space();
			create_component(route1.$$.fragment);
			t1 = space();
			create_component(route2.$$.fragment);
			t2 = space();
			create_component(route3.$$.fragment);
			t3 = space();
			create_component(route4.$$.fragment);
			t4 = space();
			create_component(route5.$$.fragment);
			t5 = space();
			create_component(route6.$$.fragment);
			t6 = space();
			create_component(route7.$$.fragment);
			t7 = space();
			create_component(route8.$$.fragment);
			t8 = space();
			create_component(route9.$$.fragment);
		},
		m(target, anchor) {
			mount_component(route0, target, anchor);
			insert(target, t0, anchor);
			mount_component(route1, target, anchor);
			insert(target, t1, anchor);
			mount_component(route2, target, anchor);
			insert(target, t2, anchor);
			mount_component(route3, target, anchor);
			insert(target, t3, anchor);
			mount_component(route4, target, anchor);
			insert(target, t4, anchor);
			mount_component(route5, target, anchor);
			insert(target, t5, anchor);
			mount_component(route6, target, anchor);
			insert(target, t6, anchor);
			mount_component(route7, target, anchor);
			insert(target, t7, anchor);
			mount_component(route8, target, anchor);
			insert(target, t8, anchor);
			mount_component(route9, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const route0_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route0_changes.$$scope = { dirty, ctx };
			}

			route0.$set(route0_changes);
			const route1_changes = {};

			if (dirty & /*$$scope, params*/ 6) {
				route1_changes.$$scope = { dirty, ctx };
			}

			route1.$set(route1_changes);
			const route2_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route2_changes.$$scope = { dirty, ctx };
			}

			route2.$set(route2_changes);
			const route3_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route3_changes.$$scope = { dirty, ctx };
			}

			route3.$set(route3_changes);
			const route4_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route4_changes.$$scope = { dirty, ctx };
			}

			route4.$set(route4_changes);
			const route5_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route5_changes.$$scope = { dirty, ctx };
			}

			route5.$set(route5_changes);
			const route6_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route6_changes.$$scope = { dirty, ctx };
			}

			route6.$set(route6_changes);
			const route7_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route7_changes.$$scope = { dirty, ctx };
			}

			route7.$set(route7_changes);
			const route8_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route8_changes.$$scope = { dirty, ctx };
			}

			route8.$set(route8_changes);
			const route9_changes = {};

			if (dirty & /*$$scope*/ 4) {
				route9_changes.$$scope = { dirty, ctx };
			}

			route9.$set(route9_changes);
		},
		i(local) {
			if (current) return;
			transition_in(route0.$$.fragment, local);
			transition_in(route1.$$.fragment, local);
			transition_in(route2.$$.fragment, local);
			transition_in(route3.$$.fragment, local);
			transition_in(route4.$$.fragment, local);
			transition_in(route5.$$.fragment, local);
			transition_in(route6.$$.fragment, local);
			transition_in(route7.$$.fragment, local);
			transition_in(route8.$$.fragment, local);
			transition_in(route9.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(route0.$$.fragment, local);
			transition_out(route1.$$.fragment, local);
			transition_out(route2.$$.fragment, local);
			transition_out(route3.$$.fragment, local);
			transition_out(route4.$$.fragment, local);
			transition_out(route5.$$.fragment, local);
			transition_out(route6.$$.fragment, local);
			transition_out(route7.$$.fragment, local);
			transition_out(route8.$$.fragment, local);
			transition_out(route9.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(route0, detaching);
			if (detaching) detach(t0);
			destroy_component(route1, detaching);
			if (detaching) detach(t1);
			destroy_component(route2, detaching);
			if (detaching) detach(t2);
			destroy_component(route3, detaching);
			if (detaching) detach(t3);
			destroy_component(route4, detaching);
			if (detaching) detach(t4);
			destroy_component(route5, detaching);
			if (detaching) detach(t5);
			destroy_component(route6, detaching);
			if (detaching) detach(t6);
			destroy_component(route7, detaching);
			if (detaching) detach(t7);
			destroy_component(route8, detaching);
			if (detaching) detach(t8);
			destroy_component(route9, detaching);
		}
	};
}

function create_fragment(ctx) {
	let router;
	let current;

	router = new Router({
			props: {
				url: /*url*/ ctx[0],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(router.$$.fragment);
		},
		m(target, anchor) {
			mount_component(router, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const router_changes = {};
			if (dirty & /*url*/ 1) router_changes.url = /*url*/ ctx[0];

			if (dirty & /*$$scope*/ 4) {
				router_changes.$$scope = { dirty, ctx };
			}

			router.$set(router_changes);
		},
		i(local) {
			if (current) return;
			transition_in(router.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(router.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(router, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { url = "" } = $$props;

	$$self.$$set = $$props => {
		if ('url' in $$props) $$invalidate(0, url = $$props.url);
	};

	return [url];
}

class Routes extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { url: 0 });
	}
}

export default Routes;