import './AffiliateScreen.svelte.css';
/* src/screens/AffiliateScreen.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import NavBar from "../components/NavBar.svelte.js";
import AffiliateForm from '../components/AffiliateForm.svelte.js';
import Footer from '../components/Footer.svelte.js';
import Loader from '../components/Loader.svelte.js';

function create_else_block_1(ctx) {
	let div;
	let h1;
	let t1;
	let p;
	let t3;
	let hr;
	let t4;
	let affiliateform;
	let current;
	affiliateform = new AffiliateForm({ props: { onDone: /*handleDone*/ ctx[4] } });
	affiliateform.$on("load", /*handleLoading*/ ctx[5]);

	return {
		c() {
			div = element("div");
			h1 = element("h1");
			h1.textContent = "Affiliate Application";
			t1 = space();
			p = element("p");
			p.textContent = "Please complete the form below to apply as a MyPetAR affiliate.";
			t3 = space();
			hr = element("hr");
			t4 = space();
			create_component(affiliateform.$$.fragment);
			attr(h1, "class", "svelte-1kr5t2g");
			attr(p, "class", "svelte-1kr5t2g");
			attr(hr, "class", "solid svelte-1kr5t2g");
			attr(div, "class", "form-container svelte-1kr5t2g");
			set_style(div, "margin-bottom", "100px");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h1);
			append(div, t1);
			append(div, p);
			append(div, t3);
			append(div, hr);
			append(div, t4);
			mount_component(affiliateform, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(affiliateform.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(affiliateform.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(affiliateform);
		}
	};
}

// (28:2) {#if isDone}
function create_if_block_1(ctx) {
	let if_block_anchor;

	function select_block_type_1(ctx, dirty) {
		if (/*hasErr*/ ctx[2]) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (23:0) {#if isLoading}
function create_if_block(ctx) {
	let div;
	let loader;
	let current;
	loader = new Loader({});

	return {
		c() {
			div = element("div");
			create_component(loader.$$.fragment);
			set_style(div, "display", "flex");
			set_style(div, "justify-content", "center");
			set_style(div, "margin-top", "50px");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(loader, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(loader.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(loader.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(loader);
		}
	};
}

// (34:4) {:else}
function create_else_block(ctx) {
	let div;
	let h3;
	let t1;
	let p;
	let t2;

	return {
		c() {
			div = element("div");
			h3 = element("h3");
			h3.textContent = "Success!";
			t1 = space();
			p = element("p");
			t2 = text(/*message*/ ctx[3]);
			attr(h3, "class", "svelte-1kr5t2g");
			attr(p, "class", "svelte-1kr5t2g");
			attr(div, "class", "res success svelte-1kr5t2g");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h3);
			append(div, t1);
			append(div, p);
			append(p, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*message*/ 8) set_data(t2, /*message*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (29:4) {#if hasErr}
function create_if_block_2(ctx) {
	let div;
	let h3;
	let t1;
	let p;
	let t2;

	return {
		c() {
			div = element("div");
			h3 = element("h3");
			h3.textContent = "Error";
			t1 = space();
			p = element("p");
			t2 = text(/*message*/ ctx[3]);
			attr(h3, "class", "svelte-1kr5t2g");
			attr(p, "class", "svelte-1kr5t2g");
			attr(div, "class", "res error svelte-1kr5t2g");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h3);
			append(div, t1);
			append(div, p);
			append(p, t2);
		},
		p(ctx, dirty) {
			if (dirty & /*message*/ 8) set_data(t2, /*message*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let navbar;
	let t0;
	let current_block_type_index;
	let if_block;
	let t1;
	let footer;
	let current;
	navbar = new NavBar({});
	const if_block_creators = [create_if_block, create_if_block_1, create_else_block_1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*isLoading*/ ctx[0]) return 0;
		if (/*isDone*/ ctx[1]) return 1;
		return 2;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	footer = new Footer({});

	return {
		c() {
			create_component(navbar.$$.fragment);
			t0 = space();
			if_block.c();
			t1 = space();
			create_component(footer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(navbar, target, anchor);
			insert(target, t0, anchor);
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, t1, anchor);
			mount_component(footer, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(t1.parentNode, t1);
			}
		},
		i(local) {
			if (current) return;
			transition_in(navbar.$$.fragment, local);
			transition_in(if_block);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(navbar.$$.fragment, local);
			transition_out(if_block);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(navbar, detaching);
			if (detaching) detach(t0);
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(t1);
			destroy_component(footer, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let isLoading = false;
	let isDone = false;
	let hasErr = false;
	let message = "";

	const handleDone = (err, msg) => {
		$$invalidate(1, isDone = true);
		$$invalidate(2, hasErr = err);
		$$invalidate(3, message = msg);
	};

	const handleLoading = event => $$invalidate(0, isLoading = event.detail.load);
	return [isLoading, isDone, hasErr, message, handleDone, handleLoading];
}

class AffiliateScreen extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default AffiliateScreen;