import './Front.svelte.css';
/* src/screens/Front.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import Hero from "../components/Hero.svelte.js";
import NavBar from "../components/NavBar.svelte.js";
import Products from "../components/Products.svelte.js";
import About from "../components/About.svelte.js";
import Infomercial from "../components/Infomercial.svelte.js";
import FAQ from "../components/FAQ.svelte.js";
import Banner from "../components/Banner.svelte.js";
import BuyNow from "../components/BuyNow.svelte.js";
import ContactUs from "../components/ContactUs.svelte.js";
import Footer from "../components/Footer.svelte.js";
import TestimonialCarousel from "../components/TestimonialCarousel.svelte.js";

function create_fragment(ctx) {
	let navbar;
	let t0;
	let hero;
	let t1;
	let products;
	let t2;
	let about;
	let t3;
	let infomercial;
	let t4;
	let banner;
	let t5;
	let buynow;
	let t6;
	let testimonialcarousel;
	let t7;
	let faq;
	let t8;
	let contactus;
	let t9;
	let footer;
	let current;
	navbar = new NavBar({});
	hero = new Hero({});
	products = new Products({});
	about = new About({});
	infomercial = new Infomercial({});
	banner = new Banner({});
	buynow = new BuyNow({});
	testimonialcarousel = new TestimonialCarousel({});
	faq = new FAQ({});
	contactus = new ContactUs({});
	footer = new Footer({});

	return {
		c() {
			create_component(navbar.$$.fragment);
			t0 = space();
			create_component(hero.$$.fragment);
			t1 = space();
			create_component(products.$$.fragment);
			t2 = space();
			create_component(about.$$.fragment);
			t3 = space();
			create_component(infomercial.$$.fragment);
			t4 = space();
			create_component(banner.$$.fragment);
			t5 = space();
			create_component(buynow.$$.fragment);
			t6 = space();
			create_component(testimonialcarousel.$$.fragment);
			t7 = space();
			create_component(faq.$$.fragment);
			t8 = space();
			create_component(contactus.$$.fragment);
			t9 = space();
			create_component(footer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(navbar, target, anchor);
			insert(target, t0, anchor);
			mount_component(hero, target, anchor);
			insert(target, t1, anchor);
			mount_component(products, target, anchor);
			insert(target, t2, anchor);
			mount_component(about, target, anchor);
			insert(target, t3, anchor);
			mount_component(infomercial, target, anchor);
			insert(target, t4, anchor);
			mount_component(banner, target, anchor);
			insert(target, t5, anchor);
			mount_component(buynow, target, anchor);
			insert(target, t6, anchor);
			mount_component(testimonialcarousel, target, anchor);
			insert(target, t7, anchor);
			mount_component(faq, target, anchor);
			insert(target, t8, anchor);
			mount_component(contactus, target, anchor);
			insert(target, t9, anchor);
			mount_component(footer, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(navbar.$$.fragment, local);
			transition_in(hero.$$.fragment, local);
			transition_in(products.$$.fragment, local);
			transition_in(about.$$.fragment, local);
			transition_in(infomercial.$$.fragment, local);
			transition_in(banner.$$.fragment, local);
			transition_in(buynow.$$.fragment, local);
			transition_in(testimonialcarousel.$$.fragment, local);
			transition_in(faq.$$.fragment, local);
			transition_in(contactus.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(navbar.$$.fragment, local);
			transition_out(hero.$$.fragment, local);
			transition_out(products.$$.fragment, local);
			transition_out(about.$$.fragment, local);
			transition_out(infomercial.$$.fragment, local);
			transition_out(banner.$$.fragment, local);
			transition_out(buynow.$$.fragment, local);
			transition_out(testimonialcarousel.$$.fragment, local);
			transition_out(faq.$$.fragment, local);
			transition_out(contactus.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(navbar, detaching);
			if (detaching) detach(t0);
			destroy_component(hero, detaching);
			if (detaching) detach(t1);
			destroy_component(products, detaching);
			if (detaching) detach(t2);
			destroy_component(about, detaching);
			if (detaching) detach(t3);
			destroy_component(infomercial, detaching);
			if (detaching) detach(t4);
			destroy_component(banner, detaching);
			if (detaching) detach(t5);
			destroy_component(buynow, detaching);
			if (detaching) detach(t6);
			destroy_component(testimonialcarousel, detaching);
			if (detaching) detach(t7);
			destroy_component(faq, detaching);
			if (detaching) detach(t8);
			destroy_component(contactus, detaching);
			if (detaching) detach(t9);
			destroy_component(footer, detaching);
		}
	};
}

class Front extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Front;