import './About.svelte.css';
/* src/components/About.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let a;
	let t0;
	let div3;

	return {
		c() {
			a = element("a");
			t0 = space();
			div3 = element("div");

			div3.innerHTML = `<div class="main svelte-1pcw95m"><div class="header svelte-1pcw95m"><img src="/img/about_us.png" alt="About Us" class="svelte-1pcw95m"/></div> 
    <div class="content svelte-1pcw95m"><h1 class="svelte-1pcw95m">ABOUT US</h1> 
      <p class="svelte-1pcw95m">We are a team of pet owners and animal lovers who work in the tech industry.  We wanted to create something special and memorable for fellow pet lovers to enjoy so we decided to add an extra layer of love to our products. Through the power of technology, we created memorable keepsakes where you can relive your favorite pet video moments just by placing your smartphone over your customized item.</p></div></div>`;

			attr(a, "id", "about");
			attr(div3, "class", "container svelte-1pcw95m");
		},
		m(target, anchor) {
			insert(target, a, anchor);
			insert(target, t0, anchor);
			insert(target, div3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(a);
			if (detaching) detach(t0);
			if (detaching) detach(div3);
		}
	};
}

class About extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default About;