import './Loader.svelte.css';
/* src/components/Loader.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div8;

	return {
		c() {
			div8 = element("div");
			div8.innerHTML = `<div class="svelte-1ct9flf"></div><div class="svelte-1ct9flf"></div><div class="svelte-1ct9flf"></div><div class="svelte-1ct9flf"></div><div class="svelte-1ct9flf"></div><div class="svelte-1ct9flf"></div><div class="svelte-1ct9flf"></div><div class="svelte-1ct9flf"></div>`;
			attr(div8, "class", "lds-roller svelte-1ct9flf");
		},
		m(target, anchor) {
			insert(target, div8, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div8);
		}
	};
}

class Loader extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Loader;