import { getShopifyStoreAddress, getStorefrontToken } from "./util.js";

const storeAddress = getShopifyStoreAddress();
const token = getStorefrontToken();

const fetchGenerator = async (method, path, body, token) => {
  const requestInfo = {
    method: method,
    body: body,
  }

  requestInfo.headers = {
    ...requestInfo.headers,
    'Content-Type': 'application/graphql', 
    'X-Shopify-Storefront-Access-Token': token
  }
  
  //TODO: identify and handle graphql errors
  let response = await fetch(storeAddress + path, requestInfo);
  if (response.ok) {
    let json = await response.json();

    // handle graphql errors
    if (json.errors) {
      console.log(json);
      throw new Error(json.errors.reduce((accum, current) =>  accum + `${current.message}, ` , ""));
    }

    return json;
  } else {
    throw new Error(await response.text())
  }
}

export default class ApiClient {
  static async getShopifyName() {
    let query = `{ shop { name description } }`;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async getProduct(id) {
    let query = `
    {
      node(id: "${id}") {
        ... on Product {
          id
          title
          description
          descriptionHtml
          variants(first: 10) {
            edges {
              cursor
              node {
                id
                title
                price
                sku
                availableForSale
              }
            }
          }
        }
      }
    }
    `;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async getProductByTitle(title) {
    let query = `{
      products (first: 1, query: "title:${title}" ) {
        edges {
          node {
            id
            title
            description
            descriptionHtml
            images(first: 10) {
              edges {
                cursor
                node {
                  transformedSrc(maxWidth:610)
                }
              }
            }
            variants(first: 10) {
              edges {
                cursor
                node {
                  id
                  title
                  price
                  compareAtPrice
                  sku
                  availableForSale
                }
              }
            }
          }
        }
      }
    }`;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async getProductByType(type) {
    let query = `{
      products (first: 1, query: "product_type:${type}" ) {
        edges {
          node {
            id
            title
            description
            descriptionHtml
            images(first: 10) {
              edges {
                cursor
                node {
                  transformedSrc(maxWidth:610)
                }
              }
            }
            variants(first: 10) {
              edges {
                cursor
                node {
                  id
                  title
                  price
                  compareAtPrice
                  sku
                  availableForSale
                }
              }
            }
          }
        }
      }
    }`;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async createCheckout() {
    let query = `
    mutation {
      checkoutCreate(input: { lineItems: [] }) 
      {
        checkout {
          id
          webUrl
        }
      }
    }
    `;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async addToCheckout(checkoutId, variantId, quantity, cartoonize, theme, photoUrl, videoUrl) {
    let query = `
    mutation {
      checkoutLineItemsAdd (
        lineItems: [
          { 
            variantId: "${variantId}", quantity: ${quantity}, customAttributes: [
              { key: "_cartoonize", value: "${cartoonize}" }
              { key: "_theme", value: "${theme}" }
              { key: "_photoUrl", value: "${photoUrl}" }
              { key: "_videoUrl", value: "${videoUrl}" }
            ] 
          }
        ], 
        checkoutId: "${checkoutId}"
      )
      {
        userErrors {
          field
          message
        }
        checkout {
          id
          lineItems(first: 5) {
            edges {
              node {
                id
                title
                quantity
                variant {
                  sku
                }
                customAttributes {
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
    `;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async removeFromCheckout(checkoutId, lineItemIds) {
    let query = `
      mutation {
        checkoutLineItemsRemove(checkoutId: "${checkoutId}", lineItemIds: "${lineItemIds}") {
          checkout {
            id
          }
          checkoutUserErrors {
            code
            field 
            message
          }
        }
      }
    `;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async updateCheckoutLineItem(checkoutId, lineItemId, quantity, theme, variantId, photoUrl, videoUrl) {
    let query = `
      mutation {
        checkoutLineItemsUpdate(
          checkoutId: "${checkoutId}",
          lineItems: [
            { 
              id: "${lineItemId}", variantId: "${variantId}", quantity: ${quantity}, customAttributes: [
                { key: "_theme", value: "${theme}" }
                { key: "_photoUrl", value: "${photoUrl}" }
                { key: "_videoUrl", value: "${videoUrl}" }
              ] 
            }
          ]
        )
        {
          checkout {
            id
          }
          checkoutUserErrors {
            code
            field
            message
          }
        }
      }
    `;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }


  static async getCheckout(checkoutId) {
    const query = `
    query {
      node(id: "${checkoutId}") {
        ... on Checkout {
          id
          ready
          currencyCode
          lineItems(first: 10) {
            edges {
              node {
                id
                title
                quantity
                variant {
                  id
                  image {
                    originalSrc
                  }
                  sku
                  selectedOptions {
                    name 
                    value
                  }
                  price
                  product {
                    title
                  }

                }
                customAttributes {
                  key
                  value
                }
              }
            }
          }
          order {
            id
            financialStatus
            fulfillmentStatus
          }
          subtotalPrice
        }
      }
    }
    `;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }

  static async getWebCheckoutUrl(checkoutId) {
    const query = `
    query {
      node(id:"${checkoutId}" ) {
        ... on Checkout {
          id
          webUrl
        }
      }
    }
    `;
    return await fetchGenerator("POST", '/api/graphql.json', query, token);
  }
  
}
