import './Cart.svelte.css';
/* src/screens/Cart.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
import NavBar from "../components/NavBar.svelte.js";
import api from "../ApiClient.js";
import { getSavedCheckoutId } from "../util.js";
import Footer from "../components/Footer.svelte.js";
import Modal from '../../_snowpack/pkg/svelte-simple-modal.js';
import { modal } from "../stores.js";
import CartItem from "../components/CartItem.svelte.js";
import CartCheckout from "../components/CartCheckout.svelte.js";
import { cartItemCount } from '../stores.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	return child_ctx;
}

// (100:26) 
function create_if_block_4(ctx) {
	let t;

	return {
		c() {
			t = text("YOUR CART IS EMPTY");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (98:44) 
function create_if_block_3(ctx) {
	let t;

	return {
		c() {
			t = text("YOUR CART IS EMPTY");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (96:6) {#if checkout && cartHasItems()}
function create_if_block_2(ctx) {
	let t;

	return {
		c() {
			t = text("YOUR CART");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (105:4) {#if checkout && cartHasItems()}
function create_if_block(ctx) {
	let section;
	let table;
	let tr;
	let t7;
	let t8;
	let show_if = /*checkout*/ ctx[0] && /*cartHasItems*/ ctx[5]();
	let if_block_anchor;
	let current;
	let each_value = /*checkout*/ ctx[0].data.node.lineItems.edges;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	let if_block = show_if && create_if_block_1(ctx);

	return {
		c() {
			section = element("section");
			table = element("table");
			tr = element("tr");

			tr.innerHTML = `<th style="width: 45%;">Product</th> 
            <th>Price</th> 
            <th>Quantity</th> 
            <th>Total</th>`;

			t7 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t8 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(table, "class", "cart-items-table svelte-izgz8u");
			attr(section, "class", "cart-items-container svelte-izgz8u");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, table);
			append(table, tr);
			append(table, t7);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(table, null);
				}
			}

			insert(target, t8, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*displayMoneyAmount, checkout, updateLineItemQuantity, handleRemoveCartItem*/ 25) {
				each_value = /*checkout*/ ctx[0].data.node.lineItems.edges;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(table, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (dirty & /*checkout*/ 1) show_if = /*checkout*/ ctx[0] && /*cartHasItems*/ ctx[5]();

			if (show_if) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*checkout*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block);
			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(section);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t8);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (114:10) {#each checkout.data.node.lineItems.edges as lineItemEdge}
function create_each_block(ctx) {
	let tr;
	let td0;
	let cartitem;
	let t0;
	let td1;
	let t1;
	let t2_value = displayMoneyAmount(/*lineItemEdge*/ ctx[9].node.variant.price) + "";
	let t2;
	let t3;
	let td2;
	let input;
	let input_value_value;
	let t4;
	let td3;
	let t5;
	let t6_value = displayMoneyAmount(/*lineItemEdge*/ ctx[9].node.variant.price * /*lineItemEdge*/ ctx[9].node.quantity) + "";
	let t6;
	let t7;
	let current;
	let mounted;
	let dispose;

	cartitem = new CartItem({
			props: {
				checkoutLineItemEdge: /*lineItemEdge*/ ctx[9]
			}
		});

	cartitem.$on("removeItem", /*handleRemoveCartItem*/ ctx[3]);

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			create_component(cartitem.$$.fragment);
			t0 = space();
			td1 = element("td");
			t1 = text("$");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			input = element("input");
			t4 = space();
			td3 = element("td");
			t5 = text("$");
			t6 = text(t6_value);
			t7 = space();
			attr(td1, "class", "centered-text svelte-izgz8u");
			attr(input, "class", "input-quantity svelte-izgz8u");
			attr(input, "type", "number");
			input.value = input_value_value = /*lineItemEdge*/ ctx[9].node.quantity;
			attr(td2, "class", "centered-text svelte-izgz8u");
			attr(td3, "class", "centered-text svelte-izgz8u");
			attr(tr, "class", "item-row svelte-izgz8u");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			mount_component(cartitem, td0, null);
			append(tr, t0);
			append(tr, td1);
			append(td1, t1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, input);
			append(tr, t4);
			append(tr, td3);
			append(td3, t5);
			append(td3, t6);
			append(tr, t7);
			current = true;

			if (!mounted) {
				dispose = listen(input, "change", function () {
					if (is_function(/*updateLineItemQuantity*/ ctx[4](/*lineItemEdge*/ ctx[9], this.value))) /*updateLineItemQuantity*/ ctx[4](/*lineItemEdge*/ ctx[9], this.value).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const cartitem_changes = {};
			if (dirty & /*checkout*/ 1) cartitem_changes.checkoutLineItemEdge = /*lineItemEdge*/ ctx[9];
			cartitem.$set(cartitem_changes);
			if ((!current || dirty & /*checkout*/ 1) && t2_value !== (t2_value = displayMoneyAmount(/*lineItemEdge*/ ctx[9].node.variant.price) + "")) set_data(t2, t2_value);

			if (!current || dirty & /*checkout*/ 1 && input_value_value !== (input_value_value = /*lineItemEdge*/ ctx[9].node.quantity) && input.value !== input_value_value) {
				input.value = input_value_value;
			}

			if ((!current || dirty & /*checkout*/ 1) && t6_value !== (t6_value = displayMoneyAmount(/*lineItemEdge*/ ctx[9].node.variant.price * /*lineItemEdge*/ ctx[9].node.quantity) + "")) set_data(t6, t6_value);
		},
		i(local) {
			if (current) return;
			transition_in(cartitem.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(cartitem.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(tr);
			destroy_component(cartitem);
			mounted = false;
			dispose();
		}
	};
}

// (130:6) {#if checkout && cartHasItems()}
function create_if_block_1(ctx) {
	let cartcheckout;
	let current;

	cartcheckout = new CartCheckout({
			props: {
				checkout: /*checkout*/ ctx[0],
				checkoutId: /*checkoutId*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(cartcheckout.$$.fragment);
		},
		m(target, anchor) {
			mount_component(cartcheckout, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const cartcheckout_changes = {};
			if (dirty & /*checkout*/ 1) cartcheckout_changes.checkout = /*checkout*/ ctx[0];
			if (dirty & /*checkoutId*/ 2) cartcheckout_changes.checkoutId = /*checkoutId*/ ctx[1];
			cartcheckout.$set(cartcheckout_changes);
		},
		i(local) {
			if (current) return;
			transition_in(cartcheckout.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(cartcheckout.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(cartcheckout, detaching);
		}
	};
}

function create_fragment(ctx) {
	let navbar;
	let t0;
	let div;
	let main;
	let h1;
	let show_if_1;
	let show_if_2;
	let t1;
	let show_if = /*checkout*/ ctx[0] && /*cartHasItems*/ ctx[5]();
	let t2;
	let footer;
	let t3;
	let modal_1;
	let current;
	navbar = new NavBar({});

	function select_block_type(ctx, dirty) {
		if (dirty & /*checkout*/ 1) show_if_1 = null;
		if (dirty & /*checkout*/ 1) show_if_2 = null;
		if (show_if_1 == null) show_if_1 = !!(/*checkout*/ ctx[0] && /*cartHasItems*/ ctx[5]());
		if (show_if_1) return create_if_block_2;
		if (show_if_2 == null) show_if_2 = !!(/*checkout*/ ctx[0] && !/*cartHasItems*/ ctx[5]());
		if (show_if_2) return create_if_block_3;
		if (!/*checkout*/ ctx[0]) return create_if_block_4;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type && current_block_type(ctx);
	let if_block1 = show_if && create_if_block(ctx);
	footer = new Footer({});

	modal_1 = new Modal({
			props: {
				show: /*$modal*/ ctx[2],
				closeButton: true
			}
		});

	modal_1.$on("closed", /*handleModalClosed*/ ctx[6]);

	return {
		c() {
			create_component(navbar.$$.fragment);
			t0 = space();
			div = element("div");
			main = element("main");
			h1 = element("h1");
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			create_component(footer.$$.fragment);
			t3 = space();
			create_component(modal_1.$$.fragment);
			attr(h1, "class", "cart-title centered-text svelte-izgz8u");
			attr(main, "class", "cart-main svelte-izgz8u");
			attr(div, "class", "cart svelte-izgz8u");
		},
		m(target, anchor) {
			mount_component(navbar, target, anchor);
			insert(target, t0, anchor);
			insert(target, div, anchor);
			append(div, main);
			append(main, h1);
			if (if_block0) if_block0.m(h1, null);
			append(main, t1);
			if (if_block1) if_block1.m(main, null);
			insert(target, t2, anchor);
			mount_component(footer, target, anchor);
			insert(target, t3, anchor);
			mount_component(modal_1, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if (if_block0) if_block0.d(1);
				if_block0 = current_block_type && current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(h1, null);
				}
			}

			if (dirty & /*checkout*/ 1) show_if = /*checkout*/ ctx[0] && /*cartHasItems*/ ctx[5]();

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*checkout*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(main, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			const modal_1_changes = {};
			if (dirty & /*$modal*/ 4) modal_1_changes.show = /*$modal*/ ctx[2];
			modal_1.$set(modal_1_changes);
		},
		i(local) {
			if (current) return;
			transition_in(navbar.$$.fragment, local);
			transition_in(if_block1);
			transition_in(footer.$$.fragment, local);
			transition_in(modal_1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(navbar.$$.fragment, local);
			transition_out(if_block1);
			transition_out(footer.$$.fragment, local);
			transition_out(modal_1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(navbar, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div);

			if (if_block0) {
				if_block0.d();
			}

			if (if_block1) if_block1.d();
			if (detaching) detach(t2);
			destroy_component(footer, detaching);
			if (detaching) detach(t3);
			destroy_component(modal_1, detaching);
		}
	};
}

function displayMoneyAmount(moneyAmount) {
	return parseFloat(moneyAmount).toFixed(2).toString();
}

function instance($$self, $$props, $$invalidate) {
	let $modal;
	component_subscribe($$self, modal, $$value => $$invalidate(2, $modal = $$value));
	let checkout;
	let checkoutId;

	onMount(async () => {
		$$invalidate(1, checkoutId = getSavedCheckoutId());
		$$invalidate(0, checkout = await api.getCheckout(checkoutId));
		console.log(checkout);
	});

	async function removeFromCheckout(lineItemEdge) {
		try {
			let res = await api.removeFromCheckout(checkoutId, [lineItemEdge.node.id]);
			$$invalidate(0, checkout.data.node.lineItems.edges = checkout.data.node.lineItems.edges.filter(edge => edge !== lineItemEdge), checkout);
			$$invalidate(0, checkout);
			cartItemCount.set(checkout.data.node.lineItems.edges.length);
			updateSubtotal();
			console.log(res);
		} catch(err) {
			console.log(err);
		}
	}

	function handleRemoveCartItem(event) {
		removeFromCheckout(event.detail.checkoutItem);
	}

	async function updateLineItemQuantity(lineItemEdge, newQuantity) {
		try {
			if (newQuantity == 0) {
				removeFromCheckout(lineItemEdge);
			} else if (newQuantity < 0) {
				alert('Please enter a quantity larger than 0!');
				return;
			}

			let theme = lineItemEdge.node.customAttributes.find(customAttribute => customAttribute.key == "_theme").value;
			let videoUrl = lineItemEdge.node.customAttributes.find(customAttribute => customAttribute.key == "_videoUrl").value;
			let photoUrl = lineItemEdge.node.customAttributes.find(customAttribute => customAttribute.key == "_photoUrl").value;
			let res = await api.updateCheckoutLineItem(checkoutId, lineItemEdge.node.id, lineItemEdge.node.quantity = newQuantity, theme, lineItemEdge.node.variant.id, photoUrl, videoUrl);
			$$invalidate(0, checkout);
			updateSubtotal();
			console.log(res);
		} catch(err) {
			console.log(err);
		}
	}

	function updateSubtotal() {
		let cartTotal = 0;

		checkout.data.node.lineItems.edges.forEach(lineItemEdge => {
			let productTotal = lineItemEdge.node.variant.price * lineItemEdge.node.quantity;
			cartTotal += productTotal;
		});

		$$invalidate(0, checkout.data.node.subtotalPrice = cartTotal.toFixed(2).toString(), checkout);
	}

	function cartHasItems() {
		if (checkout.data.node.lineItems.edges.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	async function handleModalClosed() {
		modal.set();
	}

	return [
		checkout,
		checkoutId,
		$modal,
		handleRemoveCartItem,
		updateLineItemQuantity,
		cartHasItems,
		handleModalClosed
	];
}

class Cart extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Cart;