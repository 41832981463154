import './BuyNow.svelte.css';
/* src/components/BuyNow.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	src_url_equal
} from "../../_snowpack/pkg/svelte/internal.js";

import { navigate } from "../../_snowpack/pkg/svelte-routing.js";
import OffsetButton from "./OffsetButtons.svelte.js";

function create_fragment(ctx) {
	let div1;
	let div0;
	let img;
	let img_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			img = element("img");
			if (!src_url_equal(img.src, img_src_value = "/img/cat-right.png")) attr(img, "src", img_src_value);
			attr(img, "alt", "Get your MyPetAR Now");
			attr(img, "class", "svelte-18eqdk7");
			attr(div0, "class", "content svelte-18eqdk7");
			attr(div1, "class", "container svelte-18eqdk7");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, img);

			if (!mounted) {
				dispose = listen(img, "click", /*click_handler*/ ctx[0]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self) {
	const click_handler = () => navigate("/products/photo");
	return [click_handler];
}

class BuyNow extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default BuyNow;