/* src/routing/Redirect.svelte generated by Svelte v3.59.2 */
import { SvelteComponent, init, safe_not_equal } from "../../_snowpack/pkg/svelte/internal.js";

import { navigate } from "../../_snowpack/pkg/svelte-routing.js";
import { onMount } from '../../_snowpack/pkg/svelte.js';

function instance($$self, $$props, $$invalidate) {
	let { path = "/404" } = $$props;

	onMount(() => {
		navigate(path, { replace: true });
	});

	$$self.$$set = $$props => {
		if ('path' in $$props) $$invalidate(0, path = $$props.path);
	};

	return [path];
}

class Redirect extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, null, safe_not_equal, { path: 0 });
	}
}

export default Redirect;