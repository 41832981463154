import './CartItem.svelte.css';
/* src/components/CartItem.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { createEventDispatcher } from '../../_snowpack/pkg/svelte.js';
import { bind } from '../../_snowpack/pkg/svelte-simple-modal.js';
import { modal } from "../stores.js";
import ImagePreview from './ImagePreview.svelte.js';
import VideoPreview from './VideoPreview.svelte.js';
import OffsetButton from "./OffsetButtons.svelte.js";

function create_if_block_2(ctx) {
	let img;
	let img_src_value;
	let img_alt_value;
	let mounted;
	let dispose;

	return {
		c() {
			img = element("img");
			attr(img, "class", "cart-item-product-image svelte-1cksl88");
			if (!src_url_equal(img.src, img_src_value = /*checkoutLineItemEdge*/ ctx[0].node.variant.image.originalSrc)) attr(img, "src", img_src_value);
			attr(img, "alt", img_alt_value = /*checkoutLineItemEdge*/ ctx[0].node.title);
		},
		m(target, anchor) {
			insert(target, img, anchor);

			if (!mounted) {
				dispose = listen(img, "click", function () {
					if (is_function(/*handlePhotoPreviewClick*/ ctx[2](/*checkoutLineItemEdge*/ ctx[0].node.variant.image.originalSrc))) /*handlePhotoPreviewClick*/ ctx[2](/*checkoutLineItemEdge*/ ctx[0].node.variant.image.originalSrc).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*checkoutLineItemEdge*/ 1 && !src_url_equal(img.src, img_src_value = /*checkoutLineItemEdge*/ ctx[0].node.variant.image.originalSrc)) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*checkoutLineItemEdge*/ 1 && img_alt_value !== (img_alt_value = /*checkoutLineItemEdge*/ ctx[0].node.title)) {
				attr(img, "alt", img_alt_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			mounted = false;
			dispose();
		}
	};
}

// (39:8) {#if checkoutLineItemEdge.node.customAttributes}
function create_if_block_1(ctx) {
	let t0;
	let t1_value = /*checkoutLineItemEdge*/ ctx[0].node.customAttributes.find(func).value + "";
	let t1;

	return {
		c() {
			t0 = text("Theme: ");
			t1 = text(t1_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*checkoutLineItemEdge*/ 1 && t1_value !== (t1_value = /*checkoutLineItemEdge*/ ctx[0].node.customAttributes.find(func).value + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (44:8) {#if checkoutLineItemEdge.node.variant.selectedOptions}
function create_if_block(ctx) {
	let t0;
	let t1_value = /*checkoutLineItemEdge*/ ctx[0].node.variant.selectedOptions.find(func_1).value + "";
	let t1;

	return {
		c() {
			t0 = text("Size: ");
			t1 = text(t1_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*checkoutLineItemEdge*/ 1 && t1_value !== (t1_value = /*checkoutLineItemEdge*/ ctx[0].node.variant.selectedOptions.find(func_1).value + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (49:8) <OffsetButton color="#f2a240" on:click={handlePhotoPreviewClick((checkoutLineItemEdge.node.customAttributes.find(customAttribute => customAttribute.key == "_photoUrl")).value)} customStyle="margin-top:10px;">
function create_default_slot_2(ctx) {
	let t;

	return {
		c() {
			t = text("View Photo");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (50:8) <OffsetButton color="#f2a240" on:click={handleVideoPreviewClick((checkoutLineItemEdge.node.customAttributes.find(customAttribute => customAttribute.key == "_videoUrl")).value)} customStyle="margin-top:10px;">
function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text("View Video");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (51:8) <OffsetButton color="#e01100" on:click={removeItem(checkoutLineItemEdge)} customStyle="margin-top:10px;">
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Remove");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let div3;
	let t0;
	let div2;
	let div1;
	let p0;
	let t1_value = /*checkoutLineItemEdge*/ ctx[0].node.title + "";
	let t1;
	let t2;
	let p1;
	let t3;
	let p2;
	let t4;
	let div0;
	let offsetbutton0;
	let t5;
	let offsetbutton1;
	let t6;
	let offsetbutton2;
	let current;
	let if_block0 = /*checkoutLineItemEdge*/ ctx[0].node.variant.image && create_if_block_2(ctx);
	let if_block1 = /*checkoutLineItemEdge*/ ctx[0].node.customAttributes && create_if_block_1(ctx);
	let if_block2 = /*checkoutLineItemEdge*/ ctx[0].node.variant.selectedOptions && create_if_block(ctx);

	offsetbutton0 = new OffsetButton({
			props: {
				color: "#f2a240",
				customStyle: "margin-top:10px;",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			}
		});

	offsetbutton0.$on("click", function () {
		if (is_function(/*handlePhotoPreviewClick*/ ctx[2](/*checkoutLineItemEdge*/ ctx[0].node.customAttributes.find(click_handler).value))) /*handlePhotoPreviewClick*/ ctx[2](/*checkoutLineItemEdge*/ ctx[0].node.customAttributes.find(click_handler).value).apply(this, arguments);
	});

	offsetbutton1 = new OffsetButton({
			props: {
				color: "#f2a240",
				customStyle: "margin-top:10px;",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	offsetbutton1.$on("click", function () {
		if (is_function(/*handleVideoPreviewClick*/ ctx[3](/*checkoutLineItemEdge*/ ctx[0].node.customAttributes.find(click_handler_1).value))) /*handleVideoPreviewClick*/ ctx[3](/*checkoutLineItemEdge*/ ctx[0].node.customAttributes.find(click_handler_1).value).apply(this, arguments);
	});

	offsetbutton2 = new OffsetButton({
			props: {
				color: "#e01100",
				customStyle: "margin-top:10px;",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	offsetbutton2.$on("click", function () {
		if (is_function(/*removeItem*/ ctx[1](/*checkoutLineItemEdge*/ ctx[0]))) /*removeItem*/ ctx[1](/*checkoutLineItemEdge*/ ctx[0]).apply(this, arguments);
	});

	return {
		c() {
			div3 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			div2 = element("div");
			div1 = element("div");
			p0 = element("p");
			t1 = text(t1_value);
			t2 = space();
			p1 = element("p");
			if (if_block1) if_block1.c();
			t3 = space();
			p2 = element("p");
			if (if_block2) if_block2.c();
			t4 = space();
			div0 = element("div");
			create_component(offsetbutton0.$$.fragment);
			t5 = space();
			create_component(offsetbutton1.$$.fragment);
			t6 = space();
			create_component(offsetbutton2.$$.fragment);
			attr(p0, "class", "cart-item-info-title svelte-1cksl88");
			attr(p1, "class", "cart-item-info-theme svelte-1cksl88");
			attr(p2, "class", "cart-item-info-size svelte-1cksl88");
			attr(div0, "class", "cart-item-info-preview-buttons svelte-1cksl88");
			attr(div1, "class", "cart-item-info-row");
			attr(div2, "class", "cart-item-info svelte-1cksl88");
			attr(div3, "class", "cart-item svelte-1cksl88");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			if (if_block0) if_block0.m(div3, null);
			append(div3, t0);
			append(div3, div2);
			append(div2, div1);
			append(div1, p0);
			append(p0, t1);
			append(div1, t2);
			append(div1, p1);
			if (if_block1) if_block1.m(p1, null);
			append(div1, t3);
			append(div1, p2);
			if (if_block2) if_block2.m(p2, null);
			append(div1, t4);
			append(div1, div0);
			mount_component(offsetbutton0, div0, null);
			append(div0, t5);
			mount_component(offsetbutton1, div0, null);
			append(div0, t6);
			mount_component(offsetbutton2, div0, null);
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (/*checkoutLineItemEdge*/ ctx[0].node.variant.image) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div3, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if ((!current || dirty & /*checkoutLineItemEdge*/ 1) && t1_value !== (t1_value = /*checkoutLineItemEdge*/ ctx[0].node.title + "")) set_data(t1, t1_value);

			if (/*checkoutLineItemEdge*/ ctx[0].node.customAttributes) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(p1, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*checkoutLineItemEdge*/ ctx[0].node.variant.selectedOptions) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(p2, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			const offsetbutton0_changes = {};

			if (dirty & /*$$scope*/ 32) {
				offsetbutton0_changes.$$scope = { dirty, ctx };
			}

			offsetbutton0.$set(offsetbutton0_changes);
			const offsetbutton1_changes = {};

			if (dirty & /*$$scope*/ 32) {
				offsetbutton1_changes.$$scope = { dirty, ctx };
			}

			offsetbutton1.$set(offsetbutton1_changes);
			const offsetbutton2_changes = {};

			if (dirty & /*$$scope*/ 32) {
				offsetbutton2_changes.$$scope = { dirty, ctx };
			}

			offsetbutton2.$set(offsetbutton2_changes);
		},
		i(local) {
			if (current) return;
			transition_in(offsetbutton0.$$.fragment, local);
			transition_in(offsetbutton1.$$.fragment, local);
			transition_in(offsetbutton2.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(offsetbutton0.$$.fragment, local);
			transition_out(offsetbutton1.$$.fragment, local);
			transition_out(offsetbutton2.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div3);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			destroy_component(offsetbutton0);
			destroy_component(offsetbutton1);
			destroy_component(offsetbutton2);
		}
	};
}

const func = customAttribute => customAttribute.key == "_theme";
const func_1 = option => option.name == "Size";
const click_handler = customAttribute => customAttribute.key == "_photoUrl";
const click_handler_1 = customAttribute => customAttribute.key == "_videoUrl";

function instance($$self, $$props, $$invalidate) {
	const dispatch = createEventDispatcher();
	let { checkoutLineItemEdge } = $$props;

	function removeItem(lineItemEdge) {
		dispatch('removeItem', { checkoutItem: lineItemEdge });
	}

	function handlePhotoPreviewClick(url) {
		modal.set(bind(ImagePreview, { imageUrl: url }));
	}

	function handleVideoPreviewClick(url) {
		modal.set(bind(VideoPreview, { videoUrl: url }));
	}

	$$self.$$set = $$props => {
		if ('checkoutLineItemEdge' in $$props) $$invalidate(0, checkoutLineItemEdge = $$props.checkoutLineItemEdge);
	};

	return [
		checkoutLineItemEdge,
		removeItem,
		handlePhotoPreviewClick,
		handleVideoPreviewClick
	];
}

class CartItem extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { checkoutLineItemEdge: 0 });
	}
}

export default CartItem;