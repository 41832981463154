import './ReturnPolicy.svelte.css';
/* src/screens/ReturnPolicy.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import NavBar from "../components/NavBar.svelte.js";
import Footer from "../components/Footer.svelte.js";

function create_fragment(ctx) {
	let navbar;
	let t0;
	let div;
	let t14;
	let footer;
	let current;
	navbar = new NavBar({});
	footer = new Footer({});

	return {
		c() {
			create_component(navbar.$$.fragment);
			t0 = space();
			div = element("div");

			div.innerHTML = `<h1>Return policy</h1> 
  <p>We’re so convinced you’ll absolutely love our products, that we’re willing to offer a 30 day risk free money back guarantee. If you receive your order and are not satisfied for any reason you can return the product for a refund within 30 days of making a purchase.</p> 

  <h2>Shipping items</h2> 
  <p>In order to return an order, you must contact us first and obtain and include a Return Merchandise Authorization (RMA). Returns will not be accepted without a valid Return Merchandise Authorization.
    Returns can be mailed to: 223-21300 Gordon Way, Richmond BC V6W 1M2. You will be responsible for paying for the shipping costs with regard to the items that you wish to return. We will not refund the shipping costs.
    You must take care to ensure that the goods are properly packaged so that they will not be damaged while in transit. If the product is found damaged or used beyond what it takes for us to reasonably inspect it, then we may reject a refund.
    All refunds may be subject to a 20% restocking fee.</p> 

  <h2>Contacting us</h2> 
  <p>If you would like to contact us concerning any matter relating to this Return Policy, you may send an email to customerservice@mypetar.com</p> 

  <p>This document was last updated on July 13, 2021</p>`;

			t14 = space();
			create_component(footer.$$.fragment);
			attr(div, "class", "container svelte-t8979s");
		},
		m(target, anchor) {
			mount_component(navbar, target, anchor);
			insert(target, t0, anchor);
			insert(target, div, anchor);
			insert(target, t14, anchor);
			mount_component(footer, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(navbar.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(navbar.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(navbar, detaching);
			if (detaching) detach(t0);
			if (detaching) detach(div);
			if (detaching) detach(t14);
			destroy_component(footer, detaching);
		}
	};
}

class ReturnPolicy extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ReturnPolicy;