import './FAQ.svelte.css';
/* src/components/FAQ.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let a0;
	let t0;
	let div1;

	return {
		c() {
			a0 = element("a");
			t0 = space();
			div1 = element("div");

			div1.innerHTML = `<div class="text svelte-1hovk8v"><h1 class="svelte-1hovk8v">FAQ</h1> 
    <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v"><span class="question svelte-1hovk8v">How do I view my pet&#39;s video?</span> 
        <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v">Upon receiving your package, head over to MyPetAR.com on your smartphone and click on <strong>Watch MyPetAR</strong> located at the top of your screen. Enter the email address used to place the order, follow the prompts and enjoy!</li></ul></li> 
      <li class="svelte-1hovk8v"><span class="question svelte-1hovk8v">Do you accept returns?</span> 
        <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v">Yes we do.  We have a 100% money back guarantee policy. Please see our return policy <a href="/return-policy">here</a>.</li></ul></li> 
      <li class="svelte-1hovk8v"><span class="question svelte-1hovk8v">Shipping</span> 
        <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v">Do you ship anywhere?
            <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v">We currently only ship to the United States and Canada.  We currently do not ship to P.O. boxes or APO/FPO addresses.</li></ul></li> 
          <li class="svelte-1hovk8v">Do you have expedited shipping?
            <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v">Yes. All shipping options and rates will be calculated at checkout.</li></ul></li> 
          <li class="svelte-1hovk8v">Do I have to pay any customs fees?
            <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v">Customs policies vary from country to country. All applicable fees, taxes and duties are your responsibility.</li></ul></li></ul></li> 
      <li class="svelte-1hovk8v"><span class="question svelte-1hovk8v">How do I become a MyPetAR affiliate?</span> 
        <ul class="svelte-1hovk8v"><li class="svelte-1hovk8v">Please click <a href="/affiliate">here</a> to apply to be a MyPetAR affiliate.</li></ul></li></ul></div>`;

			attr(a0, "id", "faq");
			attr(div1, "class", "container svelte-1hovk8v");
		},
		m(target, anchor) {
			insert(target, a0, anchor);
			insert(target, t0, anchor);
			insert(target, div1, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(a0);
			if (detaching) detach(t0);
			if (detaching) detach(div1);
		}
	};
}

class FAQ extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default FAQ;