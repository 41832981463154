import './ProductCTA.svelte.css';
/* src/components/ProductCTA.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	set_style,
	space,
	src_url_equal,
	text as text_1,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { Link } from "../../_snowpack/pkg/svelte-routing.js";

function create_default_slot(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			set_style(img, "border-color", /*borderColor*/ ctx[0]);
			if (!src_url_equal(img.src, img_src_value = /*imgSrc*/ ctx[3])) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "class", "svelte-hw5n6i");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*borderColor*/ 1) {
				set_style(img, "border-color", /*borderColor*/ ctx[0]);
			}

			if (dirty & /*imgSrc*/ 8 && !src_url_equal(img.src, img_src_value = /*imgSrc*/ ctx[3])) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let p;
	let t0_value = (/*text*/ ctx[1] ? /*text*/ ctx[1] : "") + "";
	let t0;
	let t1;
	let link;
	let current;

	link = new Link({
			props: {
				to: "/products/" + /*type*/ ctx[2],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div = element("div");
			p = element("p");
			t0 = text_1(t0_value);
			t1 = space();
			create_component(link.$$.fragment);
			set_style(p, "color", /*borderColor*/ ctx[0]);
			attr(p, "class", "svelte-hw5n6i");
			attr(div, "class", "svelte-hw5n6i");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p);
			append(p, t0);
			append(div, t1);
			mount_component(link, div, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((!current || dirty & /*text*/ 2) && t0_value !== (t0_value = (/*text*/ ctx[1] ? /*text*/ ctx[1] : "") + "")) set_data(t0, t0_value);

			if (!current || dirty & /*borderColor*/ 1) {
				set_style(p, "color", /*borderColor*/ ctx[0]);
			}

			const link_changes = {};
			if (dirty & /*type*/ 4) link_changes.to = "/products/" + /*type*/ ctx[2];

			if (dirty & /*$$scope, borderColor, imgSrc*/ 25) {
				link_changes.$$scope = { dirty, ctx };
			}

			link.$set(link_changes);
		},
		i(local) {
			if (current) return;
			transition_in(link.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(link.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(link);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { borderColor } = $$props;
	let { text } = $$props;
	let { type } = $$props;
	let { imgSrc } = $$props;

	$$self.$$set = $$props => {
		if ('borderColor' in $$props) $$invalidate(0, borderColor = $$props.borderColor);
		if ('text' in $$props) $$invalidate(1, text = $$props.text);
		if ('type' in $$props) $$invalidate(2, type = $$props.type);
		if ('imgSrc' in $$props) $$invalidate(3, imgSrc = $$props.imgSrc);
	};

	return [borderColor, text, type, imgSrc];
}

class ProductCTA extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			borderColor: 0,
			text: 1,
			type: 2,
			imgSrc: 3
		});
	}
}

export default ProductCTA;