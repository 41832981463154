/* src/components/MediaQuery.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	create_slot,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
const get_default_slot_changes = dirty => ({ matches: dirty & /*matches*/ 1 });
const get_default_slot_context = ctx => ({ matches: /*matches*/ ctx[0] });

function create_fragment(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[4].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[3], get_default_slot_context);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, matches*/ 9)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[3],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[3])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[3], dirty, get_default_slot_changes),
						get_default_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { query } = $$props;
	let mql;
	let mqlListener;
	let wasMounted = false;
	let matches = false;

	onMount(() => {
		$$invalidate(2, wasMounted = true);

		return () => {
			removeActiveListener();
		};
	});

	function addNewListener(query) {
		mql = window.matchMedia(query);
		mqlListener = v => $$invalidate(0, matches = v.matches);
		mql.addEventListener("change", mqlListener);
		$$invalidate(0, matches = mql.matches);
	}

	function removeActiveListener() {
		if (mql && mqlListener) {
			mql.removeEventListener("change", mqlListener);
		}
	}

	$$self.$$set = $$props => {
		if ('query' in $$props) $$invalidate(1, query = $$props.query);
		if ('$$scope' in $$props) $$invalidate(3, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*wasMounted, query*/ 6) {
			$: {
				if (wasMounted) {
					removeActiveListener();
					addNewListener(query);
				}
			}
		}
	};

	return [matches, query, wasMounted, $$scope, slots];
}

class MediaQuery extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { query: 1 });
	}
}

export default MediaQuery;