import './ContactUs.svelte.css';
/* src/components/ContactUs.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
import OffsetButton from "./OffsetButtons.svelte.js";

function create_fragment(ctx) {
	let div3;

	return {
		c() {
			div3 = element("div");

			div3.innerHTML = `<a class="anchor" id="form-anchor"></a> 
  <div class="contact-form-container svelte-1g5rsiu"><div class="contact-form-info svelte-1g5rsiu"><img class="contact-form-logo svelte-1g5rsiu" src="/img/logo.png" alt="mypetar.com"/></div> 
    
    <div class="contact-form-input svelte-1g5rsiu"><h2 class="svelte-1g5rsiu">Please feel free to contact us at <a href="mailto:customerservice@mypetar.com">customerservice@mypetar.com</a></h2></div></div>`;

			attr(div3, "class", "container svelte-1g5rsiu");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
		}
	};
}

class ContactUs extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default ContactUs;