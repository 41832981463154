import './Testimonial.svelte.css';
/* src/components/Testimonial.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	src_url_equal,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div5;
	let div4;
	let div2;
	let div0;
	let h1;
	let t1;
	let p0;
	let t2;
	let t3;
	let div1;
	let img0;
	let img0_src_value;
	let t4;
	let div3;
	let h2;
	let t5;
	let t6;
	let p1;
	let a0;
	let img1;
	let img1_src_value;
	let a0_href_value;
	let t7;
	let a1;
	let t8;
	let a1_href_value;

	return {
		c() {
			div5 = element("div");
			div4 = element("div");
			div2 = element("div");
			div0 = element("div");
			h1 = element("h1");
			h1.textContent = "„";
			t1 = space();
			p0 = element("p");
			t2 = text(/*copy*/ ctx[0]);
			t3 = space();
			div1 = element("div");
			img0 = element("img");
			t4 = space();
			div3 = element("div");
			h2 = element("h2");
			t5 = text(/*reviewerName*/ ctx[1]);
			t6 = space();
			p1 = element("p");
			a0 = element("a");
			img1 = element("img");
			t7 = space();
			a1 = element("a");
			t8 = text(/*reviewerHandle*/ ctx[2]);
			attr(h1, "class", "quote no-margin svelte-13kivrl");
			attr(p0, "class", "review-text");
			attr(div0, "class", "review flex-container flex-dir-col flex-center-items svelte-13kivrl");
			if (!src_url_equal(img0.src, img0_src_value = /*testimonialPhotoUrl*/ ctx[3])) attr(img0, "src", img0_src_value);
			attr(img0, "alt", "Reviewer");
			attr(div1, "class", "small-circle svelte-13kivrl");
			attr(div2, "class", "big-circle flex-container flex-dir-col flex-center-items svelte-13kivrl");
			attr(h2, "class", "no-margin svelte-13kivrl");
			if (!src_url_equal(img1.src, img1_src_value = "/img/ig.png")) attr(img1, "src", img1_src_value);
			attr(img1, "height", "25");
			attr(img1, "width", "25");
			attr(img1, "alt", "Instagram");
			attr(a0, "href", a0_href_value = "https://www.instagram.com/" + /*reviewerHandle*/ ctx[2]);
			set_style(a0, "vertical-align", "top");
			attr(a0, "target", "_blank");
			attr(a0, "class", "svelte-13kivrl");
			attr(a1, "href", a1_href_value = "https://www.instagram.com/" + /*reviewerHandle*/ ctx[2]);
			attr(a1, "class", "instagram-handle svelte-13kivrl");
			attr(p1, "class", "no-margin svelte-13kivrl");
			attr(div3, "class", "reviewer-info-container flex-container flex-dir-col flex-center-items svelte-13kivrl");
			attr(div4, "class", "review-container flex-container flex-dir-col flex-center-items svelte-13kivrl");
			attr(div5, "class", "testimonial flex-container flex-dir-col flex-center-items svelte-13kivrl");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div4);
			append(div4, div2);
			append(div2, div0);
			append(div0, h1);
			append(div0, t1);
			append(div0, p0);
			append(p0, t2);
			append(div2, t3);
			append(div2, div1);
			append(div1, img0);
			append(div4, t4);
			append(div4, div3);
			append(div3, h2);
			append(h2, t5);
			append(div3, t6);
			append(div3, p1);
			append(p1, a0);
			append(a0, img1);
			append(p1, t7);
			append(p1, a1);
			append(a1, t8);
		},
		p(ctx, [dirty]) {
			if (dirty & /*copy*/ 1) set_data(t2, /*copy*/ ctx[0]);

			if (dirty & /*testimonialPhotoUrl*/ 8 && !src_url_equal(img0.src, img0_src_value = /*testimonialPhotoUrl*/ ctx[3])) {
				attr(img0, "src", img0_src_value);
			}

			if (dirty & /*reviewerName*/ 2) set_data(t5, /*reviewerName*/ ctx[1]);

			if (dirty & /*reviewerHandle*/ 4 && a0_href_value !== (a0_href_value = "https://www.instagram.com/" + /*reviewerHandle*/ ctx[2])) {
				attr(a0, "href", a0_href_value);
			}

			if (dirty & /*reviewerHandle*/ 4) set_data(t8, /*reviewerHandle*/ ctx[2]);

			if (dirty & /*reviewerHandle*/ 4 && a1_href_value !== (a1_href_value = "https://www.instagram.com/" + /*reviewerHandle*/ ctx[2])) {
				attr(a1, "href", a1_href_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div5);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { copy } = $$props;
	let { reviewerName } = $$props;
	let { reviewerHandle } = $$props;
	let { testimonialPhotoUrl } = $$props;

	$$self.$$set = $$props => {
		if ('copy' in $$props) $$invalidate(0, copy = $$props.copy);
		if ('reviewerName' in $$props) $$invalidate(1, reviewerName = $$props.reviewerName);
		if ('reviewerHandle' in $$props) $$invalidate(2, reviewerHandle = $$props.reviewerHandle);
		if ('testimonialPhotoUrl' in $$props) $$invalidate(3, testimonialPhotoUrl = $$props.testimonialPhotoUrl);
	};

	return [copy, reviewerName, reviewerHandle, testimonialPhotoUrl];
}

class Testimonial extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			copy: 0,
			reviewerName: 1,
			reviewerHandle: 2,
			testimonialPhotoUrl: 3
		});
	}
}

export default Testimonial;