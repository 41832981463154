import './TestimonialCarousel.svelte.css';
/* src/components/TestimonialCarousel.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import Carousel from '../../_snowpack/pkg/svelte-carousel.js';
import Testimonial from './Testimonial.svelte.js';

function create_default_slot(ctx) {
	let testimonial0;
	let t0;
	let testimonial1;
	let t1;
	let testimonial2;
	let t2;
	let testimonial3;
	let t3;
	let testimonial4;
	let t4;
	let testimonial5;
	let t5;
	let testimonial6;
	let t6;
	let testimonial7;
	let t7;
	let testimonial8;
	let current;

	testimonial0 = new Testimonial({
			props: {
				copy: "So happy with this cool #augmentedreality gift I got from mypetar.com. Dexter comes to life and we have great #memories of our #vacation with our #pet.",
				reviewerName: "Dexter",
				reviewerHandle: "thedexfiles",
				testimonialPhotoUrl: "../img/reviews/DEXTER1.png"
			}
		});

	testimonial1 = new Testimonial({
			props: {
				copy: "It’s fantastic!",
				reviewerName: "Winky Bear",
				reviewerHandle: "winkybear_the_rottie",
				testimonialPhotoUrl: "../img/reviews/WINKY_BEAR1.png"
			}
		});

	testimonial2 = new Testimonial({
			props: {
				copy: "It came out so cute! It is not just any ordinary picture. They use augmented reality to make the artwork come to life on your phone.",
				reviewerName: "Chester",
				reviewerHandle: "chesterthedutchie",
				testimonialPhotoUrl: "../img/reviews/CHESTER1.png"
			}
		});

	testimonial3 = new Testimonial({
			props: {
				copy: "Thank you @mypetar!! A little AR action that is - loving this!! Still need to get a frame, but smoking pro quality!",
				reviewerName: "Reggie",
				reviewerHandle: "reggiecataka",
				testimonialPhotoUrl: "../img/reviews/REGGIE1.png"
			}
		});

	testimonial4 = new Testimonial({
			props: {
				copy: "We just received an amazing art work! Sooo in love. Thanks so much @mypetar. Can’t wait to put it on my wall and show you guys.",
				reviewerName: "Angel & Ava",
				reviewerHandle: "the.pink.princesses",
				testimonialPhotoUrl: "../img/reviews/THEPINK_PRINCESSES1.png"
			}
		});

	testimonial5 = new Testimonial({
			props: {
				copy: "I love it! My husband and kiddos love it too! Thank you MyPetAR!",
				reviewerName: "Munchie",
				reviewerHandle: "mydear.munchie",
				testimonialPhotoUrl: "../img/reviews/MUNCHIE1.png"
			}
		});

	testimonial6 = new Testimonial({
			props: {
				copy: "Beautiful artwork! Make sure you check out their page!",
				reviewerName: "Paris & Wish",
				reviewerHandle: "sassycatparis",
				testimonialPhotoUrl: "../img/reviews/PARIS&WISH1.png"
			}
		});

	testimonial7 = new Testimonial({
			props: {
				copy: "It’s really cute! Very cool!",
				reviewerName: "Stewie Bear",
				reviewerHandle: "stewiethechewy",
				testimonialPhotoUrl: "../img/reviews/STEWIE_BEAR1.png"
			}
		});

	testimonial8 = new Testimonial({
			props: {
				copy: "Awesome artwork from MyPetAR! We love it! It’s a photo that comes to life with an augmented reality using your phone! We love our new photo!",
				reviewerName: "Maple & Juniper",
				reviewerHandle: "lop_and_retriever",
				testimonialPhotoUrl: "../img/reviews/MAPLE_JUNIPER1.png"
			}
		});

	return {
		c() {
			create_component(testimonial0.$$.fragment);
			t0 = space();
			create_component(testimonial1.$$.fragment);
			t1 = space();
			create_component(testimonial2.$$.fragment);
			t2 = space();
			create_component(testimonial3.$$.fragment);
			t3 = space();
			create_component(testimonial4.$$.fragment);
			t4 = space();
			create_component(testimonial5.$$.fragment);
			t5 = space();
			create_component(testimonial6.$$.fragment);
			t6 = space();
			create_component(testimonial7.$$.fragment);
			t7 = space();
			create_component(testimonial8.$$.fragment);
		},
		m(target, anchor) {
			mount_component(testimonial0, target, anchor);
			insert(target, t0, anchor);
			mount_component(testimonial1, target, anchor);
			insert(target, t1, anchor);
			mount_component(testimonial2, target, anchor);
			insert(target, t2, anchor);
			mount_component(testimonial3, target, anchor);
			insert(target, t3, anchor);
			mount_component(testimonial4, target, anchor);
			insert(target, t4, anchor);
			mount_component(testimonial5, target, anchor);
			insert(target, t5, anchor);
			mount_component(testimonial6, target, anchor);
			insert(target, t6, anchor);
			mount_component(testimonial7, target, anchor);
			insert(target, t7, anchor);
			mount_component(testimonial8, target, anchor);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(testimonial0.$$.fragment, local);
			transition_in(testimonial1.$$.fragment, local);
			transition_in(testimonial2.$$.fragment, local);
			transition_in(testimonial3.$$.fragment, local);
			transition_in(testimonial4.$$.fragment, local);
			transition_in(testimonial5.$$.fragment, local);
			transition_in(testimonial6.$$.fragment, local);
			transition_in(testimonial7.$$.fragment, local);
			transition_in(testimonial8.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(testimonial0.$$.fragment, local);
			transition_out(testimonial1.$$.fragment, local);
			transition_out(testimonial2.$$.fragment, local);
			transition_out(testimonial3.$$.fragment, local);
			transition_out(testimonial4.$$.fragment, local);
			transition_out(testimonial5.$$.fragment, local);
			transition_out(testimonial6.$$.fragment, local);
			transition_out(testimonial7.$$.fragment, local);
			transition_out(testimonial8.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(testimonial0, detaching);
			if (detaching) detach(t0);
			destroy_component(testimonial1, detaching);
			if (detaching) detach(t1);
			destroy_component(testimonial2, detaching);
			if (detaching) detach(t2);
			destroy_component(testimonial3, detaching);
			if (detaching) detach(t3);
			destroy_component(testimonial4, detaching);
			if (detaching) detach(t4);
			destroy_component(testimonial5, detaching);
			if (detaching) detach(t5);
			destroy_component(testimonial6, detaching);
			if (detaching) detach(t6);
			destroy_component(testimonial7, detaching);
			if (detaching) detach(t7);
			destroy_component(testimonial8, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let carousel;
	let current;

	carousel = new Carousel({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(carousel.$$.fragment);
			attr(div0, "class", "carousel-container svelte-1oz0ru1");
			attr(div1, "class", "testimonial-carousel svelte-1oz0ru1");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(carousel, div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			const carousel_changes = {};

			if (dirty & /*$$scope*/ 1) {
				carousel_changes.$$scope = { dirty, ctx };
			}

			carousel.$set(carousel_changes);
		},
		i(local) {
			if (current) return;
			transition_in(carousel.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(carousel.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(carousel);
		}
	};
}

class TestimonialCarousel extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default TestimonialCarousel;