import './PlayCredentials.svelte.css';
/* src/components/PlayCredentials.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";

function create_fragment(ctx) {
	let div;
	let form;
	let span;
	let t1;
	let input0;
	let t2;
	let input1;

	return {
		c() {
			div = element("div");
			form = element("form");
			span = element("span");
			span.textContent = "Enter your email below to watch your pets video on your photo, t-shirt or mug";
			t1 = space();
			input0 = element("input");
			t2 = space();
			input1 = element("input");
			attr(span, "class", "label svelte-g8kenw");
			attr(input0, "type", "email");
			attr(input0, "name", "gn");
			attr(input0, "placeholder", "your email address");
			attr(input0, "class", "svelte-g8kenw");
			attr(input1, "type", "submit");
			input1.value = "PLAY NOW!";
			attr(input1, "class", "svelte-g8kenw");
			attr(form, "action", "https://play.mypetar.com/list");
			attr(form, "method", "GET");
			attr(div, "class", "container svelte-g8kenw");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, form);
			append(form, span);
			append(form, t1);
			append(form, input0);
			/*input0_binding*/ ctx[1](input0);
			append(form, t2);
			append(form, input1);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			/*input0_binding*/ ctx[1](null);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	onMount(async () => {
		gn.focus();
	});

	let gn;

	function input0_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			gn = $$value;
			$$invalidate(0, gn);
		});
	}

	return [gn, input0_binding];
}

class PlayCredentials extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default PlayCredentials;