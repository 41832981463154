import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';

/* src/App.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_style,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../_snowpack/pkg/svelte.js";
import Routes from "./routing/Routes.svelte.js";
import { isMobile } from "./util.js";

function create_fragment(ctx) {
	let main;
	let routes;
	let current;
	routes = new Routes({});

	return {
		c() {
			main = element("main");
			create_component(routes.$$.fragment);
			set_style(main, "height", "100%");
		},
		m(target, anchor) {
			insert(target, main, anchor);
			mount_component(routes, main, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(routes.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(routes.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(main);
			destroy_component(routes);
		}
	};
}

function instance($$self) {
	onMount(() => {
		console.log("The current mode is: " + __SNOWPACK_ENV__.MODE);
	});

	return [];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;