import './OffsetButtons.svelte.css';
/* src/components/OffsetButtons.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	bubble,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	listen,
	safe_not_equal,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let button;
	let button_style_value;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[8].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[7], null);

	return {
		c() {
			button = element("button");
			if (default_slot) default_slot.c();
			attr(button, "style", button_style_value = "--theme-color:" + /*color*/ ctx[1] + "; " + (/*width*/ ctx[2] ? `width: ${/*width*/ ctx[2]};` : '') + " " + (/*height*/ ctx[3] ? `height: ${/*height*/ ctx[3]};` : '') + " " + /*customStyle*/ ctx[4] + ";");
			button.disabled = /*disabled*/ ctx[6];
			attr(button, "class", "svelte-1rasphl");
			toggle_class(button, "big", /*big*/ ctx[0]);
			toggle_class(button, "dark", /*theme*/ ctx[5] === 'dark');
			toggle_class(button, "disabled", /*disabled*/ ctx[6]);
		},
		m(target, anchor) {
			insert(target, button, anchor);

			if (default_slot) {
				default_slot.m(button, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[9]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 128)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[7],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[7])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[7], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*color, width, height, customStyle*/ 30 && button_style_value !== (button_style_value = "--theme-color:" + /*color*/ ctx[1] + "; " + (/*width*/ ctx[2] ? `width: ${/*width*/ ctx[2]};` : '') + " " + (/*height*/ ctx[3] ? `height: ${/*height*/ ctx[3]};` : '') + " " + /*customStyle*/ ctx[4] + ";")) {
				attr(button, "style", button_style_value);
			}

			if (!current || dirty & /*disabled*/ 64) {
				button.disabled = /*disabled*/ ctx[6];
			}

			if (!current || dirty & /*big*/ 1) {
				toggle_class(button, "big", /*big*/ ctx[0]);
			}

			if (!current || dirty & /*theme*/ 32) {
				toggle_class(button, "dark", /*theme*/ ctx[5] === 'dark');
			}

			if (!current || dirty & /*disabled*/ 64) {
				toggle_class(button, "disabled", /*disabled*/ ctx[6]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(button);
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { big = false } = $$props;
	let { color = "lightgreen" } = $$props;
	let { width } = $$props;
	let { height } = $$props;
	let { customStyle = "" } = $$props;
	let { theme } = $$props;
	let { disabled = false } = $$props;

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('big' in $$props) $$invalidate(0, big = $$props.big);
		if ('color' in $$props) $$invalidate(1, color = $$props.color);
		if ('width' in $$props) $$invalidate(2, width = $$props.width);
		if ('height' in $$props) $$invalidate(3, height = $$props.height);
		if ('customStyle' in $$props) $$invalidate(4, customStyle = $$props.customStyle);
		if ('theme' in $$props) $$invalidate(5, theme = $$props.theme);
		if ('disabled' in $$props) $$invalidate(6, disabled = $$props.disabled);
		if ('$$scope' in $$props) $$invalidate(7, $$scope = $$props.$$scope);
	};

	return [
		big,
		color,
		width,
		height,
		customStyle,
		theme,
		disabled,
		$$scope,
		slots,
		click_handler
	];
}

class OffsetButtons extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			big: 0,
			color: 1,
			width: 2,
			height: 3,
			customStyle: 4,
			theme: 5,
			disabled: 6
		});
	}
}

export default OffsetButtons;