import './ImagePreview.svelte.css';
/* src/components/ImagePreview.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	src_url_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div;
	let img;
	let img_src_value;

	return {
		c() {
			div = element("div");
			img = element("img");
			if (!src_url_equal(img.src, img_src_value = /*imageUrl*/ ctx[0])) attr(img, "src", img_src_value);
			attr(img, "alt", "Your uploaded img");
			attr(img, "class", "svelte-e6p0si");
			attr(div, "class", "container svelte-e6p0si");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, img);
		},
		p(ctx, [dirty]) {
			if (dirty & /*imageUrl*/ 1 && !src_url_equal(img.src, img_src_value = /*imageUrl*/ ctx[0])) {
				attr(img, "src", img_src_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { imageUrl } = $$props;

	$$self.$$set = $$props => {
		if ('imageUrl' in $$props) $$invalidate(0, imageUrl = $$props.imageUrl);
	};

	return [imageUrl];
}

class ImagePreview extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { imageUrl: 0 });
	}
}

export default ImagePreview;