import './LoadingAnimation.svelte.css';
/* src/components/LoadingAnimation.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div5;

	return {
		c() {
			div5 = element("div");

			div5.innerHTML = `<div class="rect1 svelte-1vweht2"></div> 
  <div class="rect2 svelte-1vweht2"></div> 
  <div class="rect3 svelte-1vweht2"></div> 
  <div class="rect4 svelte-1vweht2"></div> 
  <div class="rect5 svelte-1vweht2"></div>`;

			attr(div5, "class", "spinner svelte-1vweht2");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div5);
		}
	};
}

class LoadingAnimation extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default LoadingAnimation;