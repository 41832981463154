import './Hero.svelte.css';
/* src/components/Hero.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div2;

	return {
		c() {
			div2 = element("div");

			div2.innerHTML = `<video loop="" autoplay="" muted="" src="/vid/hero.mp4" alt="" class="svelte-18rw0ia"></video> 
  <div class="overlay svelte-18rw0ia"></div> 
  <div class="text svelte-18rw0ia"><h1 class="svelte-18rw0ia">CARTOONIZE YOUR PET&#39;S PHOTOS</h1> 
    <p class="svelte-18rw0ia">Bring them to life with Augmented Reality!</p></div>`;

			attr(div2, "class", "container svelte-18rw0ia");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
		}
	};
}

class Hero extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Hero;